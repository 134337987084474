import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
//  import About_us from "./Pages/About_us/About_us";
import logo from "./logo.svg";
import "./assets/css/stylesheet.scss";
import "./assets/css/responsive.scss";
import "./assets/css/slick.scss";
//import './assets/js/function.js';

import Club from "./Pages/Club/Club";
import Event from "./Pages/Event/Event";
import UpcomingEvent from "./Pages/Event/UpcomingEvent";
import Club_result from "./Pages/Club_result/Club_result";
import Club_result1 from "./Pages/Club_result/Club_result1";
import Club_upcoming from "./Pages/Club_result/Club_upcoming";
import Dashboard from "./Pages/UserDashboard/Dashboard/Dashboard";
import MyRace from "./Pages/UserDashboard/MyRace/MyRace";
import MyResult from "./Pages/UserDashboard/MyResult/MyResult";
import UserResult from "./Pages/UserDashboard/MyResult/UserResult";
import Profile from "./Pages/UserDashboard/Profile/Profile";
import Settings from "./Pages/UserDashboard/Settings/Setting";
import ClubDashboard from "./Pages/ClubDashboard/ClubDashboard/Dashboard";
import EditClub from "./Pages/ClubDashboard/EditClub/EditClub";
import ApplyNewMember from "./Pages/ClubDashboard/ApplyNewMember/ApplyNewMember";
import Membership from "./Pages/ClubDashboard/Membership/Membership";
import EventDetail from "./Pages/EventDetail/EventDetail";
import RaceResultProfile from "./Pages/RaceResultProfile/RaceResultProfile";
import CreateEvent from "./Pages/ClubDashboard/CreateEvent/CreateEventNew";
import EditEvent from "./Pages/ClubDashboard/CreateEvent/Edit_event";
import AddEvent from "./Pages/AddEvent/AddEventnew";
import AddEventStep2 from "./Pages/AddEvent/AddEventStep2";
import AddEventStep3 from "./Pages/AddEvent/AddEventStep3";
import EventManagement from "./Pages/ClubDashboard/EventManagement/EventManagement";
import PaymentSuccess from "./Pages/Payment/Success";
import PaymentCancel from "./Pages/Payment/Cancel";
import PrivateRoute from "./Components/privateRoute";
import getLocalData from "./shared/localStorage/getData";
import EventTemplate from "./Pages/ClubDashboard/CreateEvent/eventTemplate";
import ClubProfile from "./Pages/ClubDashboard/Profile/Profile";
import UpdateProfile from "./Pages/ClubDashboard/Profile/UpdateProfile.js";
import ClubSettings from "./Pages/ClubDashboard/Settings/Setting";
import ManageHeatsheet from "./Pages/ClubDashboard/ManageHeatsheet/ManageHeatsheet3copy2";
import DownloadHeatsheet from "./Pages/ClubDashboard/ManageHeatsheet/DownloadHeatsheet+parti";
import DownloadExcelHeatsheet from "./Pages/ClubDashboard/ManageHeatsheet/DownloadExcel";
import EmailParticipants from "./Pages/ClubDashboard/ManageHeatsheet/EmailParticipants";
import LoginWithToken from "./Pages/Login/LoginWithToken";
import PaypalCredential from "./Pages/Payment/PaypalCredential";

import Scheduler from "./Pages/ClubDashboard/RaceInterface/Scheduler";
import UncontrolledBoard from "./Pages/ClubDashboard/RaceInterface/RaceInterface2";
import AdminDashboard from "./Pages/Admin/AdminDashboard/AdminDashboard";
import PaymentGateway from "./Pages/Admin/PaymentGateway/PaymentGateway";
import AddPaymentGateway from "./Pages/Admin/PaymentGateway/AddPaymentGateway";
import EventCost from "./Pages/Admin/eventCost/EventCost";
import AdminPrivateRoute from "./Components/adminPrivateRoute";
import HomeBanner from "./Pages/Admin/homeBanner/homeBanner";
import AddCancelClass from "./Pages/Admin/AddCancleClass/PaymentGateway";
import AllPayment from "./Pages/Admin/Payments/AllPayment";
import AllPaymentForUsers from "./Pages/ClubDashboard/Payment/AllPayment";
import RefundAdmin from "./Pages/Admin/Refund/AllPayment";
import EmailTemplate from "./Pages/Admin/EventTemplate/EmailTemplate";

import Highlights from "./Pages/ClubDashboard/Highlights/index";
import AddHighlights from "./Pages/ClubDashboard/Highlights/AddHighlights";
import PaymentDetails from "./Pages/ClubDashboard/EventManagement/PaymentDetails";

import UpdateEventStep1 from "./Pages/AddEvent/UpdateEventStep1";
import UpdateEventClassByAdmin from "./Pages/AddEvent/UpdateEventClassByAdmin";

import HowItWorks from "./Pages/How_it_works/How_it_works";
import HomeContent from "./Pages/Admin/HomeContent/HomeContent";
// import AllPageContent from "./Pages/Admin/AllPageContent/AllPageContent";
import UpdateHomeContent from "./Pages/Admin/HomeContent/UpdateHomeContent";
import MoreHighlights from "./Pages/Home/Highlights";

import AdminEvent from "./Pages/Admin/Events/AllEvent";
import JoinEventStep1 from "./Pages/Admin/Events/JoinEventStep1";
import JoinEventStep2 from "./Pages/Admin/Events/JoinEventStep2";
import ParticipantUser from "./Pages/Admin/Events/ParticipantUser";

import ManageEntries from "./Pages/AddEvent/ManageEntries";

import ManageEntriesStep1 from "./Pages/AddEvent/ManageEntriesStep1";

//import DeakHeat from '../src/Pages/runrace/deak-heat';
import DeakHeat from "../src/Pages/deak-heat/deak-heat copy";
import BypassHeat from "./Pages/deak-heat/BypassHeat";
import DownloadFees from "./Pages/ClubDashboard/ManageHeatsheet/DownloadFees";
import Chat from "./Pages/deak-heat/Chat";
import Login from "./Pages/Login/new_login";
import DownloadTransponderExcel from "./Pages/ClubDashboard/ManageHeatsheet/DownloadTransponder.js";
import DocumentViewer from "./Pages/Home/Privacyview.js";
import DownloadPayment from "./Pages/ClubDashboard/ManageHeatsheet/DownloadPayment.js";
import DownloadPayment2 from "./Pages/ClubDashboard/ManageHeatsheet/DownloadPayment2.js";
import DownloadPaymentExcel from "./Pages/ClubDashboard/ManageHeatsheet/DownloadPaymentExcel.js";
import Audios from "./Pages/Admin/Audios/Audios.jsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.jsx";
import DownloadScoreExcel from "./Pages/deak-heat/DownloadScoreExcel.js";
import ActivityStatus from "./Components/activitystatus/ActivityStatus.js";
import LiveFeed from "./Pages/LiveFeed/LiveFeed.jsx";
import ScorePdfPage from "./Pages/deak-heat/ScorePdfPage.js";
import MyFeed from "./Pages/MyFeed/MyFeed.jsx";
import Trash from "./Pages/ClubDashboard/Trash/Trash.js";
import EditHeatsheet from "./Pages/ClubDashboard/ManageHeatsheet/EditHeatsheet.js";
import Store from "./Pages/Store/Store.js";
import EventAndSeriesTab from "./Components/RaceSeries/EventAndSeriesTab.js";
import RaceSeriesResult from "./Pages/Club_result/RaceSeriesResult.js";
import NewCdHeatSheetFormat from "./Pages/ClubDashboard/ManageHeatsheet/NewCdHeatSheetFormat.js";
import NewDriverHeatSheetFormat from "./Pages/ClubDashboard/ManageHeatsheet/NewDriverHeatSheetFormat.js";
import Catagorylist from "./Pages/Admin/CatagoryManagment/Catagorylist.js";
import AddCategory from "./Pages/Admin/CatagoryManagment/AddCategory.js";
import Attributelist from "./Pages/Admin/AttributeManagement/AttributeListing.js";
import AddAttribute from "./Pages/Admin/AttributeManagement/AddAttribute.js";
import ProductList from "./Pages/Admin/ProductManagement/Productlist.js";
import AddProduct from "./Pages/Admin/ProductManagement/AddProduct.js";
import InventoryList from "./Pages/Admin/InventoryManagment/inventory.js";
import EditProduct from "./Pages/Admin/ProductManagement/EditProduct.js";
import EventTicket from "./Pages/NambaStore/EventTicket.js";
import ProductDetail from "./Pages/NambaStore/Products/ProductDetail.jsx";
import ProductListing from "./Pages/NambaStore/Products/ProductListing.jsx";
import AddToCart from "./Pages/NambaStore/Cart/cart.jsx";
import Checkout from "./Pages/NambaStore/Products/checkout.jsx";
import SuccessPage from "./Pages/NambaStore/Payments/SuccessPage.js";
import CancelledPage from "./Pages/NambaStore/Payments/cancelPage.js";
import OrderManagment from "./Pages/Admin/OrderManagment/ordermanagment.js";

function App() {
  return (
    <>
      {localStorage.getItem("user_info") ? <ActivityStatus /> : ""}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          {/* <Route path="/about-us" element={<About_us />} /> */}
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/club" element={<Club />} />
          <Route path="/event" element={<Event />} />
          <Route path="/upcoming-event" element={<UpcomingEvent />} />
          <Route path="/club-result" element={<Club_result />} />
          <Route path="/store" element={<Store />} />
          <Route path="/live-feed/:id" element={<LiveFeed />} />

          <Route path="/club-result/:id" element={<Club_result1 />} />

          <Route path="/club-upcoming" element={<Club_upcoming />} />
          <Route path="/series-result" element={<RaceSeriesResult />} />
          <Route path="/highlights/:id" element={<MoreHighlights />} />
          <Route path="/privacy-policy" element={<DocumentViewer />} />

          <Route exact path="/" element={<PrivateRoute />}>
            <Route path="/user-dashboard" element={<Dashboard />} />
            <Route path="/club-dashboard" element={<ClubDashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/setting" element={<Settings />} />
            <Route path="/club-profile" element={<ClubProfile />} />
            <Route path="/update-profile" element={<UpdateProfile />} />
            <Route path="/club-setting" element={<ClubSettings />} />
            <Route
              path="event-management/create-event"
              element={<CreateEvent />}
            />
            <Route
              path="event-management/event-template"
              element={<EventTemplate />}
            />
            <Route path="event/add-event/:id" element={<AddEvent />} />
            <Route
              path="event/add-event-step2/:id"
              element={<AddEventStep2 />}
            />
            <Route
              path="event/add-event-step3/:id"
              element={<AddEventStep3 />}
            />
            <Route
              path="event/update-join-event/:id"
              element={<UpdateEventStep1 />}
            />
            <Route path="/manage-entries/:id" element={<ManageEntries />} />
            <Route
              path="/manage-entries-step1/:id"
              element={<ManageEntriesStep1 />}
            />
            <Route path="/payments" element={<AllPayment />} />
            <Route path="/refunds" element={<RefundAdmin />} />
            <Route path="/all-events" element={<AdminEvent />} />
            <Route path="/addCancelClass" element={<AddCancelClass />} />
            <Route path="/run-race/:id" element={<DeakHeat />} />
            <Route path="/payment" element={<AllPaymentForUsers />} />
            <Route path="/my-race" element={<MyRace />} />
            <Route path="/my-race/my-feed/:id" element={<MyFeed />} />
            <Route path="/my-result" element={<MyResult />} />
            <Route path="/my-result/:id" element={<UserResult />} />
            <Route path="event/edit-event/:id" element={<EditEvent />} />
            <Route path="/event-management" element={<EventAndSeriesTab />} />
            <Route path="/trash" element={<Trash />} />
            {/*<Route path="/payment-success" element={<PaymentSuccess />} />*/}
            <Route path="/manage-heatsheet/:id" element={<ManageHeatsheet />} />
            <Route path="/edit-heats/:id" element={<EditHeatsheet />} />
            <Route
              path="/download-heatsheet/:id"
              element={<DownloadHeatsheet />}
            />
            <Route
              path="/download-new-heatsheet/:id"
              element={<NewCdHeatSheetFormat />}
            />
            <Route
              path="/download-participant-heatsheet/:id"
              element={<NewDriverHeatSheetFormat />}
            />
            {/* route to download pdf of payments */}
            <Route path="/download-fees/:id" element={<DownloadFees />} />
            {/* <Route path="/download-payments/:id" element={<DownloadPayment />}/> */}
            <Route
              path="/download-payments-excel/:id"
              element={<DownloadPaymentExcel />}
            />
            <Route
              path="/download-excel-score/:id"
              element={<DownloadScoreExcel />}
            />
            <Route path="/download-pdf-score/:id" element={<ScorePdfPage />} />
            <Route
              path="/download-payments/:id"
              element={<DownloadPayment2 />}
            />
            <Route
              path="/email-participants-pdf/:id"
              element={<EmailParticipants />}
            />
            <Route
              path="/download-excel-heatsheet/:id"
              element={<DownloadExcelHeatsheet />}
            />
            <Route
              path="/download-transponder-excel/:id"
              element={<DownloadTransponderExcel />}
            />
            <Route path="/chat" element={<Chat />} />
            <Route path="/paypal-credentail" element={<PaypalCredential />} />
            <Route path="/payment-details/:id" element={<PaymentDetails />} />
            AllPayment
          </Route>

          <Route path="/login-with-token" element={<LoginWithToken />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />

          <Route path="/edit-club" element={<EditClub />} />
          <Route path="/apply-new-member" element={<ApplyNewMember />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/event-detail/:id" element={<EventDetail />} />
          <Route
            path="/race-result-profile/:id"
            element={<RaceResultProfile />}
          />

          <Route path="/race-interface/:id" element={<Scheduler />} />
          {/* <Route path="/race-interface/:id" element={<UncontrolledBoard/>}/> */}
          <Route exact path="/" element={<AdminPrivateRoute />}>
            <Route path="/admin-dashboard" element={<AdminDashboard />} />

            <Route path="/home-content" element={<HomeContent />} />
            {/* <Route path="/allpage-content" element={<AllPageContent/>} /> */}
            <Route path="/email-template" element={<EmailTemplate />} />
            <Route path="/admin-dashboard/:id" element={<AdminDashboard />} />

            <Route path="/audios" element={<Audios />} />

            <Route path="/join-event-step1/:id" element={<JoinEventStep1 />} />
            <Route path="/join-event-step2/:id" element={<JoinEventStep2 />} />

            <Route path="/payment-gateway" element={<PaymentGateway />} />

            <Route path="/participants/:id" element={<ParticipantUser />} />

            <Route
              path="/payment-gateway/add"
              element={<AddPaymentGateway />}
            />
            <Route
              path="/update-join-event/:id"
              element={<UpdateEventClassByAdmin />}
            />

            <Route
              path="/payment-gateway/edit/:id"
              element={<AddPaymentGateway />}
            />

            <Route path="/event-cost" element={<EventCost />} />

            <Route path="/home-banner" element={<HomeBanner />} />
            <Route
              path="/update-home-content/:id"
              element={<UpdateHomeContent />}
            />
          </Route>

          <Route path="/highlights/:id" element={<Highlights />} />
          <Route path="/add-highlights/:id" element={<AddHighlights />} />

          <Route path="/add-highlights/:id" element={<AddHighlights />} />

          <Route path="/bypass-heat/:id" element={<BypassHeat />} />

          <Route path="/catagorylist" element={<Catagorylist />} />
          <Route path="/add-category" element={<AddCategory />} />

          <Route path="/attributeList" element={<Attributelist />} />
          <Route path="/add-attribute" element={<AddAttribute />} />

          <Route path="/productlist" element={<ProductList />} />
          <Route path="/edit-Product/:id?" element={<EditProduct />} />
          <Route path="/add-product" element={<AddProduct />} />
          <Route path="/InventoryList" element={<InventoryList />} />

          <Route path="/eventTicket/:id" element={<EventTicket />} />
          <Route
            path="event/update-join-event/:id"
            element={<UpdateEventStep1 />}
          />
          <Route path="/productListing" element={<ProductListing />} />
          <Route path="/productDetail/:id" element={<ProductDetail />} />

          <Route path="/addTocart" element={<AddToCart />} />
          <Route path="/Checkout" element={<Checkout />} />

          <Route path="/orderList" element={<OrderManagment/>} />

          <Route path="/payment/paymentSucess" element={<SuccessPage />} />
          <Route path="/payment/cancelledPage" element={<CancelledPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
