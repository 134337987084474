import React, { useState, useEffect } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import categoryIcon from "../../../assets/images/cat_Icon.png";
import eyeIcon from "../../../assets/images/eye.svg";
import pencilIcon from "../../../assets/images/pencil.svg";
import deleteIcon from "../../../assets/images/bin.svg";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import combinedServices from "../../../shared/services/user-service";

const ProductList = () => {
  const navigate = useNavigate();
  const [productsData, setProductsData] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteTrigger, setDeleteTrigger] = useState(false);
  const [attributesNames, setAttributesNames] = useState({});

  const totalPages = Math.ceil(totalProducts / limit);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let productsData = await combinedServices.getProducts(page, limit, searchQuery);
        if (productsData && productsData.data) {
          setProductsData(productsData.data.products || []);
          setTotalProducts(productsData.data.total || 0);
        }
      } catch (error) {
        console.error("Error fetching Products:", error);
      }
    };
    fetchProducts();
  }, [page, limit, searchQuery, deleteTrigger]);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await combinedServices.getallAttribute();      
        if (response && response.data) {
          const names = response.data.existingCatagory.map((attribute) => {
            return {
              [attribute.attribute_id]: attribute.attributes
            };
          }).reduce((acc, curr) => Object.assign(acc, curr), {});
          
          setAttributesNames(names);
        } else {
          console.error("Unexpected response format for attributes:", response);
        }
      } catch (error) {
        console.error("Error fetching attribute names:", error);
      }
    };
    fetchAttributes();
  }, []);

  const handleButtonClick = () => {
    navigate("/add-product");
  };

  const handleEditProduct = (productId) => {
    navigate(`/edit-Product/${productId}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const deleteCatagory = async (product_id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      });

      if (result.isConfirmed) {
        const response = await combinedServices.deleteProduct(product_id);
        if (response.status === 200) {
            Swal.fire(
            "Deleted!",
            response.data.message || "The Product has been deleted.",
            "success"
          );
          setDeleteTrigger((prev) => !prev);
        } else {
          Swal.fire(
            "Warning!",
            "The Product may have been deleted, but an unexpected response was received.",
            "warning"
          );
        }
      }
    } catch (error) {
      console.error("Error deleting Product:", error);
      Swal.fire("Error!", "Failed to delete the Product.", "error");
    }
  };

  const toggleProductStatus = async (id) => {
    try {
      const response = await combinedServices.changeproductStatus({
        product_id: id
      });
      if (response.status === 200) {
        setDeleteTrigger(response.data.message);
        Swal.fire(
          "Changed!",
          response.data.message || "The Status has been Updated.",
          "success"
        );
      } else {
        Swal.fire(
          "Warning!",
          "The Product status may have been Changes, but an unexpected response was received.",
          "warning"
        );
      }
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire("Error!", "Failed to change status of Product.", "error");
    }
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Product Managment</div>
          <div className="catFilterWrp">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search with Product Name and SKU Code"
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="filBtnWrp">
              <button className="nambaBtn" onClick={handleButtonClick}>
                + Add New Product
              </button>
            </div>
          </div>
          <div className="listTablWrp">
            <div className="tblWrp">
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Product Name</th>
                    <th>Attributes</th>
                    <th>SKU Code</th>
                    <th>Total QTY.</th>
                    <th>Retail Price</th>
                    <th>Cost Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {productsData.length > 0 ? (
                    productsData.map((product, index) => {
                      // Parse the images and attributes
                      let imageUrls = [];
                      try {
                        imageUrls = JSON.parse(product.images || "[]");
                      } catch (error) {
                        console.error("Failed to parse images:", error);
                      }

                      let attributes = {};
                      try {
                        attributes = JSON.parse(product.attributes || "{}");
                      } catch (error) {
                        console.error("Failed to parse attributes:", error);
                      }

                      return (
                        <tr key={product.product_id}>
                          <td>{(page - 1) * limit + index + 1}</td>
                          <td>
                            <div className="catPara">
                              <img
                                src={imageUrls[0] || categoryIcon}
                                alt={product.product_name || "Product Image"}
                              />
                              {product.product_name || "N/A"}
                            </div>
                          </td>
                          <td>
                            {attributes && Object.keys(attributes).length > 0 ? (
                              Object.entries(attributes).map(([key, value]) => {
                                const attributeName =
                                  attributesNames[key] || "Unknown Attribute";
                                return (
                                  <div key={key}>
                                    <strong>{attributeName}:</strong>{" "}
                                    {Array.isArray(value)
                                      ? value.join(", ")
                                      : value}
                                  </div>
                                );
                              })
                            ) : (
                              <span>N/A</span>
                            )}
                          </td>
                          <td>{product.sku_code || "N/A"}</td>
                          <td>{product.totalQuantity || "N/A"}</td>
                          <td>${product.retail_price || "N/A"}</td>
                          <td>${product.cost_price || "N/A"}</td>
                          <td>
                            <div className="tblBtnWrp">
                            <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={product.isAvailable}
                                  onChange={() =>
                                    toggleProductStatus(product.product_id)
                                  }
                                />
                                <span className="slider"></span>
                              </label>
                              <button className="Btn"
                                onClick={() => handleEditProduct(product.product_id)}
                              >
                                <img src={pencilIcon} alt="Edit" />
                              </button>
                              <button
                                className="Btn"
                                onClick={() =>
                                  deleteCatagory(product.product_id)
                                }
                              >
                                <img src={deleteIcon} alt="Delete" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" style={{ textAlign: "center" }}>
                        No Products Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductList;
