import React, { useState, useEffect } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import Accordion from "react-bootstrap/Accordion";
import categoryIcon from "../../../assets/images/cat_Icon.png";
import { useNavigate } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import moment from "moment";

const OrderManagment = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const totalPages = Math.ceil(totalOrders / limit);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const orderResponse = await combinedServices.getallorders(
          page,
          limit,
          searchQuery
        );
        if (orderResponse?.data?.data) {
          setOrderData(orderResponse.data.data || []);
          setTotalOrders(orderResponse.data.total || 0);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchOrders();
  }, [page, limit, searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const groupedOrders = orderData.reduce((acc, order) => {
    if (!acc[order.order_id]) acc[order.order_id] = [];
    acc[order.order_id].push(order);
    return acc;
  }, {});

  const formatDate = (dateString) => {
    return moment(dateString).format("MM/DD/YYYY HH:mm:ss"); // Formatting date
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Order Management</div>
          <div className="catFilterWrp">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search with Product Name or SKU Code"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="listTablWrp">
            <div className="tblWrp">
              {/* Table headers */}
              <table>
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Product Name</th>
                    <th>Product Variant</th>
                    <th>Quantity</th>
                    <th>Cost Price</th>
                    <th>Total Price</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Transaction ID</th>
                    <th>Ordered Date</th>
                  </tr>
                </thead>
              </table>
              <Accordion defaultActiveKey="0">
                {Object.keys(groupedOrders).map((orderId, idx) => {
                  const ordersWithSameId = groupedOrders[orderId];
                  return (
                    <Accordion.Item eventKey={String(idx)} key={orderId}>
                      <Accordion.Header>Order ID: {orderId}</Accordion.Header>
                      <Accordion.Body>
                        <table>
                          <tbody>
                            {ordersWithSameId.map((order) => {
                              const product = order.product || {};
                              const images = JSON.parse(product.images || "[]");
                              const orderAttributes = JSON.parse(
                                order.attribute || "{}"
                              );
                              const totalPrice =
                                order.quantity * product.cost_price;

                              return (
                                <tr key={order.id}>
                                  <td>{order.userId}</td>
                                  <td>
                                    <div className="catPara">
                                      <img
                                        src={images[0] || categoryIcon}
                                        alt={
                                          product.product_name ||
                                          "Product Image"
                                        }
                                      />
                                      {product.product_name || "N/A"}
                                    </div>
                                  </td>
                                  <td>
                                    {Object.keys(orderAttributes).length > 0 ? (
                                      Object.entries(orderAttributes).map(
                                        ([key, value]) => (
                                          <div key={key}>
                                            <strong>{key}:</strong> {value}
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <span>N/A</span>
                                    )}
                                  </td>
                                  <td>{order.quantity}</td>
                                  <td>${product.cost_price || "N/A"}</td>
                                  <td>${totalPrice.toFixed(2)}</td>
                                  <td>{order.paymentStatus || "N/A"}</td>
                                  <td>{order.orderStatus || "N/A"}</td>
                                  <td>{order.transaction_id || "N/A"}</td>
                                  <td>{formatDate(order.orderDate) || "N/A"}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>

            {/* Pagination */}
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderManagment;
