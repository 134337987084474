import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import getRemoveData from "../../../shared/localStorage/removeData";

const Header = () => {


    const [isActive, setActive] = useState(false);
    const db_logo = "https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/db_logo.png"
    const toggleClass = () => {
        setActive(!isActive);
    };
    const handleLogout = () => {


        getRemoveData("admin_info");
        localStorage.clear();
        window.location.href = "/login";
    }

    return (

        <>
            <a className={isActive ? 'showDBMenu active' : 'showDBMenu'}
                onClick={toggleClass} ><span><i></i><i></i><i></i></span></a>
            <div className={isActive ? 'dbAdminLeft active' : 'dbAdminLeft'}>
                <div className="dbLogo"><NavLink to="/"><img src={db_logo} alt="" /></NavLink></div>
                <div className="dbAdminMenu">
                    <ul>
                        <li><NavLink to="/admin-dashboard">SMS Template</NavLink></li>
                        <li><NavLink to="/email-template">Email Template</NavLink></li>
                        <li><NavLink to="/home-content">Home Content</NavLink></li>
                        {/* <li><NavLink to="/allpage-content">All Page Content</NavLink></li> */}
                        <li><NavLink to="/payment-gateway">Paypal Credential</NavLink></li>
                        <li><NavLink to="/audios">Audios</NavLink></li>

                        <li><NavLink to="/catagorylist">Catagory list</NavLink></li>
                        {/* <li><NavLink to="/add-category">Add Category</NavLink></li> */}
                        <li><NavLink to="/attributeList">Attribute list</NavLink></li>
                        <li><NavLink to="/productlist">Product List</NavLink></li>
                        <li><NavLink to="/InventoryList">Inventory List</NavLink></li>
                        <li><NavLink to="/orderList">Order List</NavLink></li>

                        {/*  <li><NavLink to="/all-events">Events</NavLink></li>
                    <li><NavLink to="/event-cost">Event Cost</NavLink></li>
                    <li><NavLink to="/addCancelClass">Cancel Class</NavLink></li>
                    <li><NavLink to="/payments">All Payment</NavLink></li>
                    <li><NavLink to="/refunds">Refunds</NavLink></li>

                    {/*<li><NavLink to="/home-banner">Home Banner</NavLink></li>*/}
                        <li><NavLink to="#" onClick={handleLogout}>Logout</NavLink></li>
                    </ul>
                </div>

            </div>


        </>



    )


}
export default Header;