import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import swal from "sweetalert";
import Header from "../../../Components/adminDashboard/Header/Header";
import "../CatagoryManagment/AdminNew.scss";
import combinedServices from "../../../shared/services/user-service";
import getLocalData from "../../../shared/localStorage/getData";

const AddAttribute = () => {
  const [categories, setCategories] = useState([]);
  const [adminid, setAdminid] = useState();
  const [categoryError, setCategoryError] = useState();
  const [attributeError, setAttributeError] = useState();
  const [formData, setFormData] = useState({
    category_id: "",
    attribute_name: ""
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let categoryList = await combinedServices.getCategory();
        if (categoryList && categoryList.data.categories) {
          // Filter categories where status is true
          const activeCategories = categoryList.data.categories.filter(
            (category) => category.status === true
          );

          setCategories(activeCategories || []);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (getLocalData("admin_info", "isLogin")) {
      let adminId = getLocalData("admin_info", "all").userData.user.id;
      setAdminid(adminId);
    }

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "attribute_name") {
      const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
      setFormData({
        ...formData,
        [name]: capitalizedValue
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;

    // Clear previous errors
    setCategoryError("");
    setAttributeError("");

    // Validate Category
    if (!formData.category_id.trim()) {
      setCategoryError("Category is required.");
      isValid = false;
    }

    // Validate Attribute Name
    if (!formData.attribute_name.trim()) {
      setAttributeError("Attribute value is required.");
      isValid = false;
    }

    if (!isValid) {
      return; // Stop submission if validation fails
    }

    const capitalizedAttribute =
      formData.attribute_name.charAt(0).toUpperCase() +
      formData.attribute_name.slice(1);

    try {
      let response = await combinedServices.addAttribute({
        category_id: formData.category_id,
        attributes: capitalizedAttribute,
        addedby_admin_id: adminid
      });

      if (response.data.status === 200) {
        Swal.fire(
          "Success!",
          response.data.message ||
            "Your attribute has been added successfully.",
          "success"
        );

        // Reset the form after successful submission
        setFormData({
          category_id: "",
          attribute_name: ""
        });
      } else {
        swal("Oops!", response.data.error || "Category already exists.", "error");

        console.error("Error Response:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response) {
        setAttributeError(
          error.response.data.error ||
            `Error ${error.response.data.status}: Something went wrong.`
        );
      } else {
        swal("Oops!",  "Network Error: Please check your connection or try again later.", "error");

      }
    }
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Add Attribute</div>
          <div className="nwWhtContWrp">
            <div className="addCatForm">
              <form onSubmit={handleSubmit}>
                {/* Categories Dropdown */}
                <div className="form_grpWrp">
                  <label htmlFor="category_id">
                    Categories name <span className="req">*</span>
                  </label>
                  <div className="form_grp">
                    <select
                      id="category_id"
                      name="category_id"
                      value={formData.category_id}
                      onChange={handleChange}
                      className="form_ctrl cusSel"
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option
                          key={category.category_id}
                          value={category.category_id}
                        >
                          {category.category}
                        </option>
                      ))}
                    </select>
                    {categoryError && (
                      <p className="error-message">{categoryError}</p>
                    )}
                  </div>
                </div>

                {/* Attribute Value */}
                <div className="form_grpWrp">
                  <label htmlFor="attribute_name">
                    Attribute value <span className="req">*</span>
                  </label>
                  <div className="form_grp">
                    <input
                      type="text"
                      id="attribute_name"
                      name="attribute_name"
                      value={formData.attribute_name}
                      onChange={handleChange}
                      className="form_ctrl"
                      placeholder="Enter Attribute Value"
                    />
                    {attributeError && (
                      <p className="error-message">{attributeError}</p>
                    )}
                  </div>
                </div>

                {/* Save Button */}
                <div className="form_grpWrp btnWrp">
                  <button type="submit" className="submit-button nambaBtn">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAttribute;
