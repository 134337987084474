import Header from "../../../Components/Layout/Header/Header";
import Footer from "../../../Components/Layout/Footer/Footer";
import "./ProductListing.scss";
import Detail from "../../../assets/images/detail-img.jpg";
import Visa from "../../../assets/images/visa.png";
import Paypal from "../../../assets/images/paypal.png";

const Checkout = () => {
  return (
    <>
      <div className="mainCon">
        <Header />

        <div class="mainCont">
          <section class="ContactOuter pt-5 pb-5">
            <div class="container">
              <div>
                <div className="d-flex flex-wrap">
                  <div className="col-12 col-md-7 col-lg-8 cartInfo">
                    <div className="mb-3 productHd">
                      <span>COMPLETE YOUR BOOKING</span>
                    </div>
                    <div className="inner p-3 p-sm-4 mb-4">
                      <div className="subHd mb-3">Personal Detail</div>
                      <ul className="chekcoutForm p-0 d-flex flex-wrap">
                        <li className="col-12 col-sm-6">
                          <label className="filed d-block mb-2">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="formInput"
                            placeholder="Enter your first name"
                          />
                        </li>
                        <li className="col-12 col-sm-6">
                          <label className="filed d-block mb-2">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="formInput"
                            placeholder="Enter your last name"
                          />
                        </li>
                        <li className="col-12 col-sm-6">
                          <label className="filed d-block mb-2">Email</label>
                          <input
                            type="text"
                            className="formInput"
                            placeholder="Enter your email"
                          />
                        </li>
                        <li className="col-12 col-sm-6">
                          <label className="filed d-block mb-2">
                            Phone Number
                          </label>
                          <input
                            type="text"
                            className="formInput"
                            placeholder="Enter your phone number"
                          />
                        </li>
                        <li className="col-12 col-sm-6">
                          <label className="filed d-block mb-2">Country</label>
                          <select className="formSelect">
                            <option>Select country</option>
                          </select>
                        </li>
                      </ul>
                    </div>
                    <div className="inner p-3 p-sm-4">
                      <div className="subHd mb-3 d-flex justify-content-between">
                        <div>Debit Card</div>
                        <div>
                          <img src={Visa} alt="" className="ms-3" />
                        </div>
                      </div>
                      <ul className="chekcoutForm p-0 d-flex flex-wrap">
                        <li className="col-12">
                          <label className="filed d-block mb-2">
                            Name on Card
                          </label>
                          <input type="text" className="formInput" />
                        </li>
                        <li className="col-12">
                          <label className="filed d-block mb-2">
                            Card Number
                          </label>
                          <input type="text" className="formInput" />
                        </li>
                        <li className="col-12 col-sm-6">
                          <label className="filed d-block mb-2">
                            Expiry Date
                          </label>
                          <input
                            type="text"
                            className="formInput"
                            placeholder="DD/MM"
                          />
                        </li>
                        <li className="col-12 col-sm-6">
                          <label className="filed d-block mb-2">
                            Security Code
                          </label>
                          <input type="text" className="formInput" />
                        </li>
                      </ul>
                      <div className="pt-5">
                        <button type="button" className="formBtn">
                          Continue to Payment
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-5 col-lg-4 myCart pt-4 pt-md-0 ps-0  ps-md-3 ps-lg-5">
                    <div className="mb-3 productHd">
                      <span>YOUR CART</span>
                    </div>
                    <div className="borderBox p-3 p-sm-4">
                      <div className="d-flex flex-wrap align-items-center">
                        <div className="col-6 col-lg-4">
                          <img src={Detail} alt="" />
                        </div>
                        <div className="col-6 col-lg-8 ps-3">
                          <div className="name">
                            <span className="d-block mb-2">RAFFLE TICKET</span>{" "}
                            SKU RIGGERSTD2
                          </div>
                        </div>
                      </div>
                      <div className="price mt-3">$50.00USD</div>
                      <div className="quantity mt-4">
                        Quantity: <input type="number" defaultValue="1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Checkout;
