import React, { useState, useEffect } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import eyeIcon from "../../../assets/images/eye.svg";
import categoryIcon from "../../../assets/images/cat_Icon.png";
import pencilIcon from "../../../assets/images/pencil.svg";
import deleteIcon from "../../../assets/images/bin.svg";
import { useNavigate } from "react-router-dom";
import combinedServices from "../../../shared/services/user-service";
import Swal from "sweetalert2";

const Attributelist = () => {
  const [categories, setCategories] = useState([]);
  const [totalCategories, setTotalCategories] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedvieCategory, setSelectedviewCategory] = useState(null);
  const [selecteddeleteCategory, setSelecteddeleteCategory] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalviewOpen, setModalviewOpen] = useState(false);
  const [modaldeleteOpen, setModaldeleteOpen] = useState(false);

  const [deleteStatus, setDeleteStatus] = useState();

  const navigate = useNavigate();

  const totalPages = Math.ceil(totalCategories / limit);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        let categoryList = await combinedServices.getAttribute(
          page,
          limit,
          searchQuery
        );
        if (categoryList && categoryList.data.categories) {
          setCategories(categoryList.data.categories || []);
          setTotalCategories(categoryList.data.total || 0);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, [page, limit, searchQuery, modalOpen, deleteStatus]);

  useEffect(() => {
    if (selecteddeleteCategory) {
      const updatedCategory = categories.find(
        (cat) => cat.category_id === selecteddeleteCategory.category_id
      );
      setSelecteddeleteCategory(updatedCategory || null);
    }
  }, [categories]);
  

  // editmodal
  const handleEditClick = (category) => {
    setSelectedCategory(category);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false); // Close the modal
  };

  // viewmodal
  const viewAttribute = (category) => {
    setSelectedviewCategory(category); // Set the selected category
    setModalviewOpen(true); // Open the modal
  };

  const handleModalviewClose = () => {
    setModalviewOpen(false); // Close the modal
  };

  // deletemodal
  const deleteAttribute = (category) => {
    setSelecteddeleteCategory(category); // Set the selected category
    setModaldeleteOpen(true); // Open the modal
  };

  const handleModaldeleteClose = () => {
    setModaldeleteOpen(false); // Close the modal
  };

  const handleButtonClick = () => {
    navigate("/add-attribute");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(Number(e.target.value));
    setPage(1);
  };

  const updateAttributes = async (category_id, attributes) => {
    try {
      if (!category_id || !attributes) {
        throw new Error("Category ID and name must be provided.");
      }

      const attributeData = { category_id, attributes };
      console.log("asda", attributeData);
      const updateAttribute = await combinedServices.updateAttributes(
        attributeData
      );

      if (updateAttribute?.status === 200) {
        setModalOpen(false);
        console.log(`Category with ID ${category_id} successfully updated.`);
        Swal.fire(
          "Updated!",
          updateAttribute.data.message || "The category has been Updated.",
          "success"
        );
      } else {
        Swal.fire("Warning!", "Error in updation", "warning");
      }
    } catch (error) {
      Swal.fire("Warning!", "Error in updation", error, "warning");
    }
  };

  const deleteAttributes = async (category_id, attribute_id) => {
    try {
      if (!category_id || !attribute_id) {
        throw new Error("Category ID and Attribute Id must be provided.");
      }
      const updateAttribute = await combinedServices.deleteAttribute(category_id,attribute_id);

      if (updateAttribute?.status === 200) {
        setModalOpen(false);
        setDeleteStatus(updateAttribute)
        Swal.fire(
          "Updated!",
          updateAttribute.data.message || "The category has been Updated.",
          "success"
        );
      } else {
        Swal.fire("Warning!", "Error in Deletion", "warning");
      }
    } catch (error) {
      Swal.fire("Warning!", "Error in Deletion", error, "warning");
    }
  };

  return (
    <>
      <Header />
      <div className="dbAdminRight newDbRight">
        <div className="dbMid dbInner">
          <div className="dbMainHd">Attribute Managment</div>
          <div className="catFilterWrp ">
            <form>
              <div className="catFilterCont">
                <div className="filWrp">
                  <label className="label2">Showing</label>
                  <select
                    value={limit}
                    onChange={handleLimitChange}
                    className="form_ctrl ctrlDrop"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span className="ent">entries</span>
                  <div className="srchGrp">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="form_ctrl ctrlSrch"
                      placeholder="Search Categories"
                    />
                  </div>
                </div>
              </div>
            </form>
            <button className="nambaBtn" onClick={handleButtonClick}>
              + Add New
            </button>
          </div>
          <div className="listTablWrp">
            <div className="tblWrp">
              <table>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Category Name</th>
                    <th>Attributes</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.length > 0 ? (
                    categories.map((category, index) => (
                      <tr key={category.category_id}>
                        <td>{(page - 1) * limit + index + 1}</td>
                        <td>
                          <div className="catPara">
                            <img
                              src={category.category_img || categoryIcon}
                              alt="categoryIcon"
                            />
                            {category.category}
                          </div>
                        </td>
                        <td>
                          {category.attributes && category.attributes.length > 0
                            ? category.attributes
                                .map((attr) => attr.attributes)
                                .join(", ")
                            : "No Attributes"}
                        </td>
                        <td>
                          <div className="tblBtnWrp">
                            <button
                              className="Btn"
                              onClick={() => viewAttribute(category)}
                            >
                              <img src={eyeIcon} alt="eyeIcon" />
                            </button>
                            <button
                              className="Btn"
                              onClick={() => handleEditClick(category)}
                            >
                              <img src={pencilIcon} alt="pencilIcon" />
                            </button>
                            <button
                              className="Btn"
                              onClick={() => deleteAttribute(category)}
                            >
                              <img src={deleteIcon} alt="deleteIcon" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No categories available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="tblPagiWrp">
              <ul className="pagination namPagi">
                <li>
                  <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                  >
                    Prev
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button
                      className={page === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <CategoryModal
        isOpen={modalOpen}
        onClose={handleModalClose}
        category={selectedCategory}
        updateAttributes={updateAttributes}
      />
      <CategoryViewModal
        isOpen={modalviewOpen}
        onClose={handleModalviewClose}
        category={selectedvieCategory}
      />
      <CategorydeleteModal
        isOpen={modaldeleteOpen}
        onClose={handleModaldeleteClose}
        category={selecteddeleteCategory}
        deleteAttributes={deleteAttributes}
      />
    </>
  );
};

export default Attributelist;

// Modal for edit
const CategoryModal = ({ isOpen, onClose, category, updateAttributes }) => {
  const [categoryName, setCategoryName] = useState("");
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (category) {
      setCategoryName(category.category || "");
      setAttributes(category.attributes || []);
    }
  }, [category]);

  if (!isOpen) return null;

  const handleSave = () => {
    if (category && category.category_id && attributes.length > 0) {
      updateAttributes(category.category_id, attributes);
    } else {
      Swal.fire("Warning!", "No attributes to update.", "warning");
    }
  };

  const handleInputChange = (e, index) => {
    const updatedAttributes = [...attributes];
    updatedAttributes[index].attributes = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    setAttributes(updatedAttributes);
  };

  return (
    <div className="catagorylistmodal">
      <div className="catagorylistmodal-content">
        <h2>Edit Attributes</h2>
        <br />
        <form className="category-form">
          {attributes.length > 0 ? (
            attributes.map((attribute, index) => (
              <div key={index} className="attribute-field">
                <label htmlFor={`attribute-${index}`}>
                  Attribute {index + 1}
                </label>
                <input
                  id={`attribute-${index}`}
                  type="text"
                  value={attribute.attributes}
                  onChange={(e) => handleInputChange(e, index)}
                  placeholder={`Enter attribute ${index + 1}`}
                />
              </div>
            ))
          ) : (
            <p>No attributes available to edit.</p>
          )}
          {attributes.length > 0 && (
            <div className="catagorylistmodal-actions">
              <button type="button" className="save-btn" onClick={handleSave}>
                Save
              </button>
            </div>
          )}
        </form>
        <div className="cancel-icon" onClick={onClose}>
          &#10006;
        </div>
      </div>
      <div className="catagorylistmodal-overlay" onClick={onClose}></div>
    </div>
  );
};

// Modal for view
const CategoryViewModal = ({ isOpen, onClose, category }) => {
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (category) {
      setAttributes(category.attributes || []);
    }
  }, [category]);

  if (!isOpen) return null;

  return (
    <div className="catagorylistmodal">
      <div className="catagorylistmodal-content">
        <h2>All Attributes</h2>
        <br />
        <form className="category-form">
          {attributes.length > 0 ? (
            attributes.map((attribute, index) => (
              <div key={index} className="attribute-field">
                <label htmlFor={`attribute-${index}`}>
                  Attribute {index + 1}
                </label>
                <input
                  id={`attribute-${index}`}
                  type="text"
                  value={attribute.attributes}
                  placeholder={`Enter attribute ${index + 1}`}
                  readOnly
                />
              </div>
            ))
          ) : (
            <p>No attributes available to view.</p>
          )}

          <div className="cancel-icon" onClick={onClose}>
            &#10006;
          </div>
        </form>
      </div>
      <div className="catagorylistmodal-overlay" onClick={onClose}></div>
    </div>
  );
};

// Modal for delete
const CategorydeleteModal = ({ isOpen, onClose, category,deleteAttributes }) => {
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (category) {
      setAttributes(category.attributes || []);
    }
  }, [category]);

  if (!isOpen) return null;

  const handleSave = (attributeId) => {
    if (category && category.category_id && attributeId) {
      console.log('Deleting attribute with ID:', attributeId);
      deleteAttributes(category.category_id, attributeId);

      setAttributes((prevAttributes) =>
        prevAttributes.filter((attribute) => attribute.attribute_id !== attributeId)
      );
    } else {
      Swal.fire("Warning!", "No attribute selected to delete.", "warning");
    }
  };

  return (
    <div className="catagorylistmodal">
      <div className="catagorylistmodal-content">
        <h2>Delete Attributes</h2>
        <br />
        <form className="category-form">
          {attributes.length > 0 ? (
            attributes.map((attribute, index) => (
              <div key={index} className="attribute-field">
                <label htmlFor={`attribute-${index}`}>Attribute {index + 1}</label>
                <div className="attribute-input-wrapper">
                  <input
                    id={`attribute-${index}`}
                    type="text"
                    value={attribute.attributes}
                    placeholder={`Enter attribute ${index + 1}`}
                    readOnly
                  />
                  <button
                    type="button"
                    className="delete-attribute-btn"
                    onClick={() => handleSave(attribute.attribute_id)}>
                    <img src={deleteIcon} alt="deleteIcon" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No attributes available to delete.</p>
          )}
          <div className="cancel-icon" onClick={onClose}>
            &#10006;
          </div>
        </form>
      </div>
      <div className="catagorylistmodal-overlay" onClick={onClose}></div>
    </div>
  );
};