import React, { useEffect, useState } from "react";
import Header from "../../../Components/adminDashboard/Header/Header";
import "./AdminNew.scss";
import uploadIcon from  "../../../assets/images/upload_icon_blue.svg";
import combinedServices from "../../../shared/services/user-service";
import swal from "sweetalert";
import getLocalData from "../../../shared/localStorage/getData";

const AddCategory = () => {

    const [formData, setFormData] = useState({
        categoryName: '',
        file: null,
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageError, setImageError] = useState();
    const [categoryError, setCategoryError] = useState("");
    const [uploadImageUrl, setUploadImageUrl] = useState();
    const [adminid, setAdminid] =useState()

    useEffect(()=>{
        if (getLocalData("admin_info", "isLogin")) {
            let adminId = getLocalData("admin_info", "all").userData.user.id;
            setAdminid(adminId)
        }
    })

    const handleChange = (event) => {
        const { name, value, files } = event.target;
    
        if (files && files[0]) {
            const file = files[0];
            const formdata = new FormData();
            formdata.append("image", file);
            setUploadImageUrl(formdata);
            setSelectedFile(file);
        } else {
            // Capitalize the first letter of every word
            const capitalizedValue = value
                .split(' ') // Split the string by spaces
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                .join(' '); // Join them back together
    
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: capitalizedValue,
            }));
        }
    };
    
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setSelectedFile(file);
            const formdata = new FormData();
            formdata.append("image", file);
    
            setUploadImageUrl(formdata);
            setImageError("");
        }
    };

    const handleImageFormSubmit = async (e) => {
        e.preventDefault();
    
        let isValid = true;
        setCategoryError("");
        setImageError("");
    
        // Validate inputs
        if (!formData.categoryName.trim()) {
            setCategoryError("Category name is required.");
            isValid = false;
        }
    
        if (!uploadImageUrl) {
            setImageError("Image is required.");
            isValid = false;
        }
    
        if (!isValid) return;
    
        try {
            // Upload the image
            const response = await combinedServices.uploadImageFromAdmin(uploadImageUrl);
    
            if (response.status === 200) {
                const imageUrl = response.data.location;
    
                const data = {
                    category_img: imageUrl,
                    category: formData.categoryName,
                    addedby_admin_id: adminid,
                    status: true,
                };
    
                const responseHomeContent = await combinedServices.addCategory(data);
    
                if (responseHomeContent.status === 200) {
                    // Success message
                    swal("Success", "Category Created Successfully", "success").then(() => {
                        document.getElementById("myForm").reset();
                    });
                    setFormData({ categoryName: "", file: null });
                    setSelectedFile(null);
                } 
                else if (responseHomeContent.status === 409) {
                    // Duplicate category error
                    swal("Oops!", responseHomeContent.error || "Category already exists.", "error");
                } 
                else {
                    // General failure message for other errors
                    swal("Error", responseHomeContent.error || "Failed to add category.", "error");
                }
            } 
            else {
                // Handle image upload failure
                setImageError("Failed to upload image. Please try again.");
            }
        } catch (error) {
            console.error("Error uploading image or adding category:", error);
            
            if (error.response && error.response.data && error.response.data.error) {
                // Backend error message if available
                swal("Error", error.response.data.error, "error");
            } else {
                // Generic error message
                setImageError("An unexpected error occurred during image upload or category creation.");
            }
        }
    };
    

    return (
        <>
            <Header />
            <div className="dbAdminRight newDbRight">
                <div className="dbMid dbInner">
                    <div className="dbMainHd">Category Information</div>
                    <div className="nwWhtContWrp">
                        <div className="addCatForm">
                            <form onSubmit={handleImageFormSubmit} id="myForm">
                                <div className="form_grpWrp">
                                    <label htmlFor="categoryName">Catagory Name <span className="req">*</span>    </label>
                                    <div className="form_grp">
                                        <input
                                            type="text"
                                            id="categoryName"
                                            name="categoryName"
                                            value={formData.categoryName}
                                            onChange={handleChange}
                                            className="form_ctrl"
                                        />
                                    {categoryError && <p className="error-message">{categoryError}</p>}
                                    </div>
                                </div>

                                {/* File Input */}
                                <div className="form_grpWrp">
                                    <label htmlFor="file">Upload Images <span className="req">*</span></label>
                                    <div className="custom-file-input-container form_grp">
                                        <label className="custom-file-label">
                                            <input
                                                type="file"
                                                onChange={handleFileChange}
                                                className="custom-file-input"
                                                accept="image/*" // Restrict to image files
                                            />
                                            <div className="custom-file-button">
                                                  <img src={uploadIcon} alt="upload icon"/>
                                                  <p>Drop your images here or select <span>click to browse</span></p>
                                            </div>
                                        </label>
                                        {selectedFile && <p className="file-name">Selected File: {selectedFile.name}</p>}
                                        {imageError && <p className="error-message">{imageError}</p>}
                                    </div>

                                </div>
                                <div className="form_grpWrp btnWrp">
                                    <button type="submit" className="submit-button nambaBtn">
                                        Save
                                    </button>
                                </div>
                            </form>

                        </div>

                    </div>


                </div>
            </div>
        </>
    );

}

export default AddCategory;
