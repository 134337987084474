import React, { Component } from "react";
import ReactDOM from "react-dom";
import Header from "../../../Components/clubDashboard/Header/Header";
import "./ManageHeatsheet.scss";
import { NavLink, Link } from "react-router-dom";
import getLocalData from "../../../shared/localStorage/getData";
import combinedServices from "../../../shared/services/user-service";
//import moment from "moment";
import Multiselect from "multiselect-react-dropdown";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Pagination from "react-js-pagination";
import history from "../../../shared/history";
import { date } from "yup/lib/locale";
import { data, valHooks } from "jquery";
import { Spinner } from "reactstrap";
import userConstants from "../../../shared/constants/user-constants";
import getRemoveData from "../../../shared/localStorage/removeData";
import Switch from "react-switch";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { useLocation } from "react-router-dom";
import DownloadTransponderExcel from "./DownloadTransponder.js";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import moment from "moment-timezone";
import EditHeatsheet from "./EditHeatsheet.js";
//moment.tz.setDefault("Asia/Kolkata");
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
moment.tz.setDefault("timeZone");

class UncontrolledBoard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      eventParticipant: [],
      eventDetails: [],
      eventId: "",
      cancelNonQualify: false,
      toggleShow: false,
      isOpen: false,
      dateArray: [],
      selectedClass: [],
      selectedDate: "",
      cancelledClass: [],
      cancelledClassMsg: "",
      baseURL: userConstants.baseURL,
      rounds: [],
      classes: [],
      combinedHeat: [],
      open: false,
      splitOpen: false,
      mergeClassId: "",
      splitValues: [],
      splitHeatNo: "",
      mergeHeatNo: "",
      selHeat: [],
      isLoder: true,
      mockdata: [],
      mockdata1: [],
      mockdata2: [],
      mockdata3: [],
      Round: 1,
      roundreg: "",
      scratchData: [],
      data: [],
      Round2Data: false,
      Round3Data: false,
      Round4Data: false,
      userClasses: {},
      userSelected: "",
      regenrate: "",
      anchorEl: null,
      eventDates: [],
      // let regenrate = useParams().regenrate;
    };
  }

  componentDidMount = async () => {
    var round = [];
    var classArr = [];

    const yourParamName = window.location.pathname;
    const youradminUrl = yourParamName.split("/");
    var participantArray = [];
    this.setState({
      eventId: youradminUrl[2],
    });

    var finalArr = [];
    var tempClass = [];
    await combinedServices
      .getAllHeatSheet({ event_id: youradminUrl[2] })
      .then((res) => {
        if (res.status == 401) {
          swal(res.msg, "Please Re-login", "warning").then(() => {
            getRemoveData("user_info");
            localStorage.clear();
            window.location.href = "/login";
          });
        }
        // this.setState({mockData:res.data})
        res.data.map((val, i) => {
          insertClass().then((finalArr) => {});
          function insertClass() {
            return new Promise((resolve, reject) => {
              val.participants.map((item) => {
                // if(item.round==1){
                if (
                  tempClass.indexOf(item.event_participant_class.class.name) ==
                  -1
                ) {
                  tempClass.push(item.event_participant_class.class.name);
                  finalArr.push({
                    class: item.event_participant_class.class.name,
                    heat: val.heat_number,
                    heatId: item.id,
                    userId:
                      item.event_participant_class.event_participant.user.id,
                    name:
                      item.event_participant_class.event_participant.user
                        .user_firstname +
                      " " +
                      item.event_participant_class.event_participant.user
                        .user_lastname,
                    score: item.score,
                    round: item.round,
                    scratch: item.scratch,
                    finish: item.finish,
                    scratchUser:
                      item.event_participant_class.event_participant.user
                        .scratchUser,
                  });
                } else {
                  finalArr.push({
                    class: item.event_participant_class.class.name,
                    heat: val.heat_number,
                    heatId: item.id,
                    userId:
                      item.event_participant_class.event_participant.user.id,
                    name:
                      item.event_participant_class.event_participant.user
                        .user_firstname +
                      " " +
                      item.event_participant_class.event_participant.user
                        .user_lastname,
                    score: item.score,
                    round: item.round,
                    scratch: item.scratch,
                    finish: item.finish,
                    scratchUser:
                      item.event_participant_class.event_participant.user
                        .scratchUser,
                  });
                }
              });
              resolve(finalArr);
            });
          }
        });
        this.setState({ scratchData: finalArr });
      })
      .catch((err) => {
        // setIsLoder(false);
        //alert(err);
      });

    //generate heet

    const data = {
      event_id: Number(youradminUrl[2]),
      //class_id:Number(heatClass) ,
      round: Number(this.state.Round),
    };
    await combinedServices.createHeat(data);

    var eventClassesData1 = await combinedServices.countByClasses(
      youradminUrl[2]
    );
    if (eventClassesData1.status == 401) {
      swal(eventClassesData1.msg, "Please Re-login", "warning").then(() => {
        getRemoveData("user_info");
        localStorage.clear();
        window.location.href = "/login";
      });
    }
    var participantClassData = eventClassesData1.data.participants;
    if (participantClassData) {
      participantClassData.map((classVal) => {
        if (classVal.run_date != null && classVal.class_status == "MADE") {
          var data = {
            id: classVal.class_id,
            name: classVal.class_name,
            run_date: classVal.run_date,
            event_class_id: classVal.event_class_id,
            order_key: classVal.order_key,
          };

          // classArr.push(data);

          classArr.push(data);
          classArr.sort((a, b) => {
            const dateA = new Date(a.run_date);
            const dateB = new Date(b.run_date);

            if (dateA.getTime() === dateB.getTime()) {
              return a.order_key - b.order_key;
            } else {
              return dateA - dateB;
            }
          });
          this.setState({ isLoder: false });
        }
      });
    }

    let eventClassesData = await combinedServices.getClassByEventId(
      youradminUrl[2]
    );

    if (eventClassesData.status == 200) {
      let eventDetail = eventClassesData.data;

      for (var i = 1; i <= eventDetail.rounds_per_class; i++) {
        round.push(i);
      }

      this.setState({
        eventDetails: eventDetail,
        rounds: round,
        classes: classArr,
      });

      this.setState({ isLoder: false });
    } else if (eventClassesData.status == 401) {
      this.showTokenExpireError(eventClassesData.msg);
    } else {
      swal("Opps", eventClassesData.msg, "error");
    }

    var heatClass = document.getElementById("heatClass").value;
    var heatRound = document.getElementById("heatRound").value;

    var heat_Data = {
      event_id: youradminUrl[2],
      class_id: heatClass,
      round: heatRound,
      heat_number: 1,
    };
    let heatResponse = await combinedServices.getHeatSheet(heat_Data);

    if (heatResponse.status == 200) {
      this.setState({ eventParticipant: heatResponse.data });

      heatResponse.data.map((value) => {
        value.participants.map((heats) => {
          if (heats.event_participant_class.participation_status == "ENTERED") {
            var data = {
              heat_number: value.heat_number,
              user:
                heats.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                heats.event_participant_class.event_participant.user
                  .user_lastname,
              type: "heat" + value.heat_number,
              class_heat_number: heats.class_heat_number,
              event_heat_number: heats.event_heat_number,
              user_id: heats.event_participant_class.event_participant.user.id,
              id: heats.id,
              participant_id: heats.event_participant_class.id,
              participant_status:
                heats.event_participant_class.participation_status,
            };
            participantArray.push(data);
          }
        });
      });

      this.setState({
        tasks: participantArray,
      });
    } else if (heatResponse.status == 401) {
      this.showTokenExpireError(heatResponse.msg);
    } else {
      swal("Opps", heatResponse.msg, "error");
    }
    await combinedServices
      .getAllHeatSheet({ event_id: this.state.eventId })
      .then(async (res) => {
        res.data.map((val, i) => {
          return val.participants.map((itm, i) => {
            return finalArr.push({
              class: itm?.event_participant_class.class.name,
              heat: val.heat_number,
              name:
                itm.event_participant_class.event_participant.user
                  .user_firstname +
                " " +
                itm.event_participant_class.event_participant.user
                  .user_lastname,
              score: "",
              round: itm.round,
              id: itm.event_participant_class.event_participant.user.id,
              scratch: itm.scratch,
            });
          });
        });
        let uniqueChars = [...new Set(finalArr)];
        finalArr?.map((val) => {
          if (val.round == 2) return this.setState({ Round2Data: true });
          if (val.round == 3) return this.setState({ Round3Data: true });
          if (val.round == 4) return this.setState({ Round4Data: true });
        });
        this.setState({ data: finalArr });
      })

      .catch((err) => {
        alert(err);
      });
    const userClasses = {};
    this.state.data
      .filter((i) => i.class != undefined)
      .forEach((item) => {
        const { name, class: participantClass } = item;
        if (!userClasses[name]) {
          userClasses[name] = [
            { class: participantClass, scratch: item.scratch },
          ];
        }
        const existingClass = userClasses[name].find(
          (classItem) => classItem.class == participantClass
        );
        if (existingClass) {
          existingClass.scratch = item.scratch;
        } else {
          userClasses[name].push({
            class: participantClass,
            scratch: item.scratch,
          });
        }
      });

    this.setState({ userClasses: userClasses });
    const searchParams = new URLSearchParams(window.location.search);
    const regenrate = searchParams.get("regenrate");
    const regkey = localStorage.getItem("regkey");
    if (regenrate == "false") {
      this.handleHeatForm();
      // this.setState({ isLoder: false });
    }

    const timeaccotolocation2 = (x, timeZone) => {
      const utcMoment = moment.utc(x);
      const localMoment = utcMoment.tz(timeZone);
      return localMoment.format("ddd MMM DD YYYY HH:mm:ss");
    };

    if (this.state.eventDetails.length !== 0) {
      var eventStartDate = timeaccotolocation2(
        this.state.eventDetails.event_start,
        this.state.eventDetails.time_zone
      );
      var eventEndDate = timeaccotolocation2(
        this.state.eventDetails.event_end,
        this.state.eventDetails.time_zone
      );

      const startDate = moment(eventStartDate, "ddd MMM DD YYYY");
      const endDate = moment(eventEndDate, "ddd MMM DD YYYY");

      // Function to generate dates between start and end dates
      function generateDatesBetween(start, end) {
        const dates = [];
        let currentDate = moment(start);
        while (currentDate <= end) {
          dates.push(currentDate.format("MMM DD, YYYY"));
          currentDate = currentDate.clone().add(1, "days");
        }
        return dates;
      }
      const allDatesBetween = generateDatesBetween(startDate, endDate);
      this.setState({
        eventDates: allDatesBetween,
      });
    }
  };

  showTokenExpireError = (msg) => {
    swal("Opps", msg, "error").then((value) => {
      getRemoveData("user_info");
      window.location.href = "/";
    });
  };
  handleHeatForm1 = async () => {
    var heatClass = document.getElementById("heatClass").value;

    let heatsGenerated = [];

    for (let i = 0; i < this.state.rounds.length; i++) {
      let round = this.state.rounds[i];
      let data = {
        event_id: Number(this.state.eventId),
        round: Number(round),
      };

      let heatResponse = await combinedServices.createHeat(data);

      if (heatResponse.status == 200) {
        heatsGenerated.push(round);
      }
    }

    // swal({
    //   title: "Are you sure?",
    //   text: "Once you generated heat, you will not be able to recover previous heat data!",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // }).then(async (willDelete) => {
    //   if (willDelete) {
    for (let i = 0; i < this.state.rounds.length; i++) {
      let round = this.state.rounds[i];
      let data = {
        event_id: Number(this.state.eventId),
        round: Number(round),
      };

      let removeHeatsResponse = await combinedServices.removeHeats(data);
      if (removeHeatsResponse.status != 200) {
        // swal("!Opps", removeHeatsResponse.msg, "error");
        return;
      }

      let generateHeatResponse = await combinedServices.generateHeat(data);
      if (generateHeatResponse.status != 200) {
        // swal("!Opps", generateHeatResponse.msg, "error");
        return;
      }
    }

    this.getAllHeatData(this.state.eventId);
    // swal("Heat successfully generated", { icon: "success" });
  };

  handleHeatForm = async () => {
    this.setState({ isLoder: true });
    var heatClass = document.getElementById("heatClass").value;

    let heatsGenerated = [];

    for (let i = 0; i < this.state.rounds.length; i++) {
      let round = this.state.rounds[i];
      let data = {
        event_id: Number(this.state.eventId),
        round: Number(round),
      };

      let heatResponse = await combinedServices.createHeat(data);

      if (heatResponse.status == 200) {
        heatsGenerated.push(round);
      }
    }

    swal({
      title: "Are you sure?",
      text: "Once you generated heat, you will not be able to recover previous heat data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        for (let i = 0; i < this.state.rounds.length; i++) {
          let round = this.state.rounds[i];
          let data = {
            event_id: Number(this.state.eventId),
            round: Number(round),
          };

          let removeHeatsResponse = await combinedServices.removeHeats(data);
          if (removeHeatsResponse.status != 200) {
            swal("!Opps", removeHeatsResponse.msg, "error");
            return;
          }

          // let generateHeatResponse = await combinedServices.generateHeat(data);
          // if (generateHeatResponse.status != 200) {
          //   swal("!Opps", generateHeatResponse.msg, "error");
          //   return;
          // }
          localStorage.setItem("regkey", "true");
        }
        let data = {
          round: 1,
          event_id: Number(this.state.eventId),
        };
        let generateHeatResponse = await combinedServices.generateHeat(data);
        if (generateHeatResponse.status != 200) {
          swal("!Opps", generateHeatResponse.msg, "error");
          return;
        }
        this.getAllHeatData(this.state.eventId);

        swal("Heat successfully generated", { icon: "success" });
        this.setState({ isLoder: false });
      } else {
        // Run the function even when Cancel is pressed
        this.getAllHeatData(this.state.eventId);

        swal("Cancelled", "No Heat Generated.", "info");
        this.setState({ isLoder: false });
      }
    });
    // }
  };
  // Download csv file of heatsheet

  mockData = () => {
    var arr3 = [
      ...this.state.rounds,
      ...this.state.classes,
      ...this.state.tasks,
    ];

    return (data = { arr3 });
  };
  // mockData =[this.state.rounds]

  getAllHeatData = async (eventId) => {
    this.setState({ tasks: [] });

    var participantArray = [];

    var heatClass = document.getElementById("heatClass").value;
    var heatRound = document.getElementById("heatRound").value;

    var heat_Data = {
      event_id: eventId,
      class_id: heatClass,
      round: heatRound,
      heat_number: 1,
    };

    var heatResponse = await combinedServices.getHeatSheet(heat_Data);

    if (heatResponse.status == 200) {
      this.setState({ eventParticipant: heatResponse.data });

      heatResponse.data.map((value, index) => {
        value.participants.map((heats) => {
          var data = {
            heat_number: value.heat_number,
            user:
              heats.event_participant_class.event_participant.user
                .user_firstname +
              " " +
              heats.event_participant_class.event_participant.user
                .user_lastname,
            type: "heat" + value.heat_number,
            class_heat_number: heats.class_heat_number,
            event_heat_number: heats.event_heat_number,
            user_id: heats.event_participant_class.event_participant.user.id,
            id: heats.id,
            participant_id: heats.event_participant_class.id,
            participant_status:
              heats.event_participant_class.participation_status,
          };
          participantArray.push(data);
        });
      });

      this.setState({
        tasks: participantArray,
      });
      this.setState({ isLoder: false });
    }
  };

  handleShowHeatBox = (e, heatDetails, selectedHeat) => {
    var combinedHeat = this.state.combinedHeat;
    let arr = [...combinedHeat];
    if (combinedHeat.length == 0) {
      let targetHeat = {
        class_heat_number: String(
          selectedHeat.participants[0].class_heat_number
        ),
        event_heat_number: String(
          selectedHeat.participants[0].event_heat_number
        ),
      };
      arr.push(targetHeat);
    }
    if (e.target.checked) {
      let newArray = {
        class_heat_number: String(
          heatDetails.participants[0].class_heat_number
        ),
        event_heat_number: String(
          heatDetails.participants[0].event_heat_number
        ),
      };
      arr.push(newArray);
      this.setState({ combinedHeat: arr });
    } else {
      arr = arr.filter(
        (value, combineIndex) =>
          value.class_heat_number !=
          heatDetails.participants[0].class_heat_number
      );
      this.setState({ combinedHeat: arr });
    }
  };
  handleSplitCheckBox = (e, heatDetails) => {
    var combinedHeat = this.state.combinedHeat;
    let arr = [...combinedHeat];
    if (e.target.checked) {
      let newArray = {
        class_heat_number: String(heatDetails.class_heat_number),
        event_heat_number: String(heatDetails.heat),
        event_participant_class_id: Number(
          heatDetails.event_participant_class_id
        ),
      };
      arr.push(newArray);
      this.setState({ combinedHeat: arr }, () => {});
    } else {
      arr = arr.filter(
        (value, combineIndex) =>
          value.event_participant_class_id !=
          heatDetails.event_participant_class_id
      );
      this.setState({ combinedHeat: arr }, () => {});
    }
  };

  onDragStart = (event, task) => {
    event.dataTransfer.setData("heatNumber", task.heat_number);
    event.dataTransfer.setData("heatUser", task.user);
    event.dataTransfer.setData("class_heat_number", task.class_heat_number);
    event.dataTransfer.setData("event_heat_number", task.event_heat_number);
    event.dataTransfer.setData("user_id", task.user_id);
    event.dataTransfer.setData("id", task.participant_id);
    event.dataTransfer.setData("class_id", task.class_id);
    event.dataTransfer.setData("participant_status", task.participant_status);
    event.dataTransfer.setData("participant_id", task.participant_id);

    //event.dataTransfer.setData("classId", task.class_id);
  };
  onDragOver = (event, id) => {
    event.preventDefault();
    //this.callAssignedClassForDate(id)

    //this.setState({ selectedDate: id })
  };

  onDrop = async (event, cat, heatValue) => {
    var id = event.dataTransfer.getData("id");

    var dragClass = event.dataTransfer.getData("class_id");

    var dropClass = heatValue.participants[0].event_participant_class.class.id;

    if (dragClass == dropClass) {
      var mergeData = {
        id: [id],
        class_heat_number: heatValue.participants[0].class_heat_number,
        event_heat_number: heatValue.participants[0].event_heat_number,
      };

      let moveHeatResponse = await combinedServices.moveHeatSheet(mergeData);
      if (moveHeatResponse.status == 200) {
        this.getAllHeatData(this.state.eventId);
      }
    } else {
      swal("Please drop in same class of user");
    }

    //this.callAssignedClassForDate();
  };
  // merge heats function///
  handleMerge = async (e, value, item) => {
    this.setState({ mergeClassId: item.id, mergeHeatNo: value });
    this.setState({ open: true });
  };
  // split heats function
  handleSplit = async (e, value, splitHeat_No) => {
    this.setState({
      splitOpen: true,
      splitValues: value,
      splitHeatNo: value[0].heat,
    });
  };
  // Toggle for Modal
  toggle = () => {
    this.setState({ open: false, splitOpen: false });
    this.getAllHeatData(this.state.eventId);
  };

  handleMergeHeatAction = async () => {
    var combinedHeat = this.state.combinedHeat;
    var eventId = this.state.eventId;

    if (combinedHeat.length < 2) {
      swal("Opps", "Please check atleast two heat for merge", "error");
    } else {
      //var heatClass = document.getElementById("heatClass").value;
      var heatRound = document.getElementById("heatRound").value;

      var mergeData = {
        event_id: Number(eventId),
        class_id: Number(this.state.mergeClassId),
        round: Number(heatRound),
        heat_numbers: combinedHeat,
      };
      let heatMergeResponse = await combinedServices.mergeHeatSheet(mergeData);

      if (heatMergeResponse.status == 200) {
        swal("Yes", "Successfully merge heats", "success").then(
          async (value) => {
            this.getAllHeatData(eventId);
            //window.location.href = "/manage-heatsheet/"+eventId;
            document.getElementById("myForm").reset();
            this.setState({ open: false, splitOpen: false, combinedHeat: [] });
            window.location.reload();
          }
        );
      } else if (heatMergeResponse.status == 401) {
        swal("Opps", heatMergeResponse.msg, "error").then((value) => {
          getRemoveData("user_info");
          window.location.href = "/";
        });
      } else {
        swal("Opps", heatMergeResponse.msg, "error").then((value) => {
          //getRemoveData("user_info");
          window.location.href = "/";
        });
      }
    }
  };
  handleSplitHeatAction = async () => {
    var splitArray = this.state.combinedHeat;
    var eventId = this.state.eventId;

    if (splitArray.length < 1) {
      swal("Opps", "Please check atleast 1 Participant for Split", "error");
    } else {
      //var heatClass = document.getElementById("heatClass").value;
      var heatRound = document.getElementById("heatRound").value;

      var splitData = {
        event_id: Number(eventId),
        round: Number(heatRound),
        splitArray,
      };

      let heatSplitResponse = await combinedServices.splitHeatSheet(splitData);

      if (heatSplitResponse.status == 200) {
        swal("Yes", "Successfully Split heat", "success").then(
          async (value) => {
            this.getAllHeatData(eventId);
            //window.location.href = "/manage-heatsheet/"+eventId;
            this.setState({ open: false, splitOpen: false, combinedHeat: [] });
            window.location.reload();
          }
        );
      } else if (heatSplitResponse.status == 401) {
        swal("Opps", heatSplitResponse.msg, "error").then((value) => {
          getRemoveData("user_info");
          window.location.href = "/";
        });
      } else {
        swal("Opps", heatSplitResponse.msg, "error").then((value) => {
          //getRemoveData("user_info");
          window.location.href = "/";
        });
      }
    }
  };
  handleChange = async (nextChecked, id) => {
    var data = {
      id: id,
    };
    if (nextChecked) {
      data.participation_status = "ENTERED";
    } else {
      data.participation_status = "SUSPENDED";
    }

    let updateStatus = await combinedServices.updateUserStatus(data);
    if (updateStatus.status == 200) {
      this.getAllHeatData(this.state.eventId);
    } else {
      swal("!Opps", updateStatus.msg, "error");
    }
  };

  handleHeatByClass = () => {
    this.getAllHeatData(this.state.eventId);
  };

  handleHeatByRound = (e) => {
    this.setState({ Round: e.target.value });
    this.getAllHeatData(this.state.eventId);
    this.setState({ roundreg: e.target.value });
  };

  handleActivate = (val) => {
    this.setState({
      userSelected: val.user.user_firstname + " " + val.user.user_lastname,
    });
    let heatId = [];

    this.state.scratchData.map((item) => {
      if (item.userId == val.user.id)
        return heatId.push({ heatId: item.heatId });
    });

    const heatOptions = this.state.userClasses[
      val.user.user_firstname + " " + val.user.user_lastname
    ]
      .filter((item) => item.scratch == true)
      .map(
        (item, index) =>
          `
 <div class="swal-inputsbutton">
    <input type="checkbox" class="checkInpt" id="${item.class}" name="${item.class}" value="${item.class}">
    <label for=${item.class}>${item.class}</label>
    </div>
  `
      )
      .join("");

    swal("Allow participant to?", "", "warning", {
      buttons: {
        current: {
          text: "Current heat only!",
          value: "current",
        },
        all: {
          text: "All heats!",
          value: "all",
        },
        selected: {
          text: "Select class!",
          value: "selected",
        },
        cancel: "Cancel",
      },
      content: {
        element: "div",
        attributes: {
          id: "heatOptions",
        },
        elementParams: {
          innerHTML: heatOptions,
        },
      },
    }).then((value) => {
      switch (value) {
        case "all":
          // swal("Done!", `Participant allowed for all heats!`, "success");
          let data = [];

          heatId.map((val) => {
            return data.push({ id: val.heatId, scratch: false });
          });
          combinedServices
            .updateScoreStatus(data,this.state.eventId)
            .then((res) => {
              swal(
                "Done!",
                `${
                  val.user.user_firstname + " " + val.user.user_lastname
                } allowed joined the all heats`,
                "success"
              );
              this.componentDidMount();
            })
            .catch((err) => {
              alert(err);
            });

          break;

        case "current":
          combinedServices
            .updateScoreStatus([{ id: val.participant_id, scratch: false }],this.state.eventId)
            .then((res) => {
              swal(
                "Done!",
                `${val.user.user_firstname} allowed joined the current heat`,
                "success"
              );
              this.componentDidMount();
            })
            .catch((err) => {
              alert(err);
            });

          break;

        case "selected":
          // Handle selected heats logic here
          swal({
            title: "Select Class",
            text: "",
            // icon: "info",
            buttons: {
              cancel: "Cancel",
              confirm: "Confirm",
            },
            content: {
              element: "div",
              attributes: {
                id: "heatOptions",
              },
              elementParams: {
                innerHTML: heatOptions,
              },
            },
          }).then((value) => {
            if (value) {
              const selectedClasses = Array.from(
                document.querySelectorAll('input[type="checkbox"]:checked')
              ).map((checkbox) => checkbox.value);

              if (selectedClasses.length > 0) {
                // Perform the desired action with the selected classes
                // For example, update the score status for the selected classes
                let heatId = [];

                this.state.scratchData
                  .filter((item) => selectedClasses.includes(item.class))
                  .map((item) => {
                    if (item.userId === val.user.id)
                      return heatId.push({ heatId: item.heatId });
                  });

                // const selectedHeatIds = heatId.filter((item) =>
                //   selectedClasses.includes(item.class)
                // );

                combinedServices
                  .updateScoreStatus(
                    heatId.map((item) => ({ id: item.heatId, scratch: false })),this.state.eventId
                  )
                  .then((res) => {
                    swal(
                      "Done!",
                      `${val.user.user_firstname} allowed joined the selected class`,
                      "success"
                    );
                    this.componentDidMount();
                  })
                  .catch((err) => {
                    alert(err);
                  });
              }
            }
          });

          const heatOptionsContainer = document.getElementById("heatOptions");
          if (heatOptionsContainer) {
            heatOptionsContainer.innerHTML = heatOptions;
          }
          break;

        default:
      }
    });
  };
  // handleDeActivate = (val) => {
  //   let heatId = [];
  //   this.state.scratchData.map((item) => {
  //     if (item.userId == val.user.id)
  //       return heatId.push({ heatId: item.heatId });
  //   });
  //   swal("Scratch participant from?", "", "warning", {
  //     buttons: {
  //       cancel: "Cancel",
  //       current: {
  //         text: "Current heat only!",
  //         value: "current",
  //       },
  //       all: {
  //         text: "All heats!",
  //         value: "all",
  //       },
  //     },
  //   }).then((value) => {
  //     switch (value) {
  //       case "all":
  //         let data = [];
  //         heatId.map((val) => {
  //           return data.push({ id: val.heatId, scratch: true });
  //         });

  //         combinedServices
  //           .updateScoreStatus(data)
  //           .then((res) => {
  //             swal(
  //               "Done!",
  //               `${
  //                 val.user.user_firstname + " " + val.user.user_lastname
  //               } removed from all heats!`,
  //               "success"
  //             );
  //             this.componentDidMount();
  //           })
  //           .catch((err) => {
  //             alert(err);
  //           });

  //         break;

  //       case "current":
  //         combinedServices
  //           .updateScoreStatus([{ id: val.participant_id, scratch: true }])
  //           .then((res) => {
  //             swal(
  //               "Done!",
  //               `${val.user.user_firstname} can not participate in the race`,
  //               "success"
  //             );
  //             this.componentDidMount();
  //           })
  //           .catch((err) => {
  //             alert(err);
  //           });

  //         break;

  //       default:
  //     }
  //   });
  // };
  handleDeActivate = (val) => {
    this.setState({
      userSelected: val.user.user_firstname + " " + val.user.user_lastname,
    });
    let heatId = [];

    this.state.scratchData.map((item) => {
      if (item.userId === val.user.id)
        return heatId.push({ heatId: item.heatId });
    });

    const heatOptions = this.state.userClasses[
      val.user.user_firstname + " " + val.user.user_lastname
    ]
      ?.map(
        (item, index) =>
          `
          <div class="swal-inputsbutton">
    <input type="checkbox" class="checkInpt" id="${item.class}" name="${item.class}" value="${item.class}">
    <label for=${item.class}>${item.class}</label>
    </div>
  `
      )
      .join("");

    swal({
      title: "Scratch participant from?",
      text: "",
      icon: "warning",
      width: "12rem",
      buttons: {
        current: {
          text: "Current heat only!",
          value: "current",
        },
        all: {
          text: "All heats!",
          value: "all",
        },
        selected: {
          text: "Select class!",
          value: "selected",
        },
        cancel: "Cancel",
      },
      content: {
        element: "div",
        attributes: {
          id: "heatOptions",
        },
        elementParams: {
          innerHTML: heatOptions,
        },
      },
    }).then((value) => {
      switch (value) {
        case "all":
          // Handle all heats logic here
          let data = [];
          heatId.map((val) => {
            return data.push({ id: val.heatId, scratch: true });
          });

          combinedServices
            .updateScoreStatus(data,this.state.eventId)
            .then((res) => {
              swal(
                "Done!",
                `${
                  val.user.user_firstname + " " + val.user.user_lastname
                } removed from all heats!`,
                "success"
              );
              this.componentDidMount();
            })
            .catch((err) => {
              alert(err);
            });

          break;

        case "current":
          // Handle current heat logic here
          combinedServices
            .updateScoreStatus([{ id: val.participant_id, scratch: true }],this.state.eventId)
            .then((res) => {
              swal(
                "Done!",
                `${val.user.user_firstname} cannot participate in the current heat`,
                "success"
              );
              this.componentDidMount();
            })
            .catch((err) => {
              alert(err);
            });

          break;

        case "selected":
          // Handle selected heats logic here
          swal({
            title: "Select Class",
            text: "",
            // icon: "info",
            buttons: {
              cancel: "Cancel",
              confirm: "Confirm",
            },
            content: {
              element: "div",
              attributes: {
                id: "heatOptions",
              },
              elementParams: {
                innerHTML: heatOptions,
              },
            },
          }).then((value) => {
            if (value) {
              const selectedClasses = Array.from(
                document.querySelectorAll('input[type="checkbox"]:checked')
              ).map((checkbox) => checkbox.value);

              if (selectedClasses.length > 0) {
                // Perform the desired action with the selected classes
                // For example, update the score status for the selected classes
                let heatId = [];
                this.state.scratchData
                  .filter((item) => selectedClasses.includes(item.class))
                  .map((item) => {
                    if (item.userId === val.user.id)
                      return heatId.push({ heatId: item.heatId });
                  });
                // const selectedHeatIds = heatId.filter((item) =>
                //   selectedClasses.includes(item.class)
                // );

                combinedServices
                  .updateScoreStatus(
                    heatId.map((item) => ({ id: item.heatId, scratch: true })),this.state.eventId
                  )
                  .then((res) => {
                    swal(
                      "Done!",
                      `${val.user.user_firstname} cannot participate in the selected classes`,
                      "success"
                    );
                    this.componentDidMount();
                  })
                  .catch((err) => {
                    alert(err);
                  });
              }
            }
          });

          const heatOptionsContainer = document.getElementById("heatOptions");
          if (heatOptionsContainer) {
            heatOptionsContainer.innerHTML = heatOptions;
          }
          break;

        default:
      }
    });
  };

  handleFormatClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleFormatClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      round2Data,
      round3Data,
      round4Data,
      data,
      userClasses,
      userSelected,
    } = this.state;

    const { anchorEl } = this.state;
    const openFormatSelect = Boolean(anchorEl);

    var eventDetails = this.state.eventDetails;
    var roundArr = this.state.rounds;
    var classes = this.state.classes;

    var tasks = {};

    this.state.eventParticipant.map((value) => {
      tasks["heat" + value.heat_number] = [];
    });

    if (this.state.tasks.length > 0) {
      this.state.tasks.forEach((task, index) => {
        tasks[task.type].push(
          <div
            onDragStart={(event) => this.onDragStart(event, task)}
            draggable
            className="draggable listItem"
            style={{ backgroundColor: "#fff", color: "01458b" }}
          >
            {task.user}

            <div className="toggleSwitch">
              <Switch
                onChange={(e) => this.handleChange(e, task.participant_id)}
                checked={task.participant_status == "ENTERED" ? true : false}
                Name="react-switch"
                offColor="#e70000"
                onColor="#1ce10d"
                onHandleColor="#fff"
                uncheckedIcon={false}
                checkedIcon={false}
                handleDiameter={14}
                height={22}
                width={48}
                className="react-switch"
              />
            </div>

            <span class="material-icons dragIcon">zoom_out_map</span>
          </div>
        );
      });
    }

    return (
      <>
        {/* this.setState({ isLoder: false }); */}
        <Header />
        <div className="dbRight">
          <div className="dbMid dbInner">
            <div className="flexBox">
              <div className="mhLeft">
                <div className="dbMainHd">Heat Management</div>
                <div
                  className="bradcrumb"
                  style={{ backgroundColor: "#1c60a6" }}
                >
                  <a href="/">Home</a>
                  <a href="/event-management">Event Management</a>
                  <a href={`/race-interface/${this.state.eventId}`}>
                    Scheduler
                  </a>
                </div>
              </div>

              <div>
                <div className="mhrTop flexBox spacebetween itemCenter">
                  <div className="flexBox left">
                    <div id="heatClass" onChange={this.handleHeatByClass}>
                      {classes.map((classData) => {
                        return <span value={classData.id}></span>;
                      })}
                    </div>

                    <select
                      className="formSelect round"
                      id="heatRound"
                      onChange={(e) => this.handleHeatByRound(e)}
                      // value="Round 1"
                    >
                      {roundArr.map((roundVal, i) => {
                        return (
                          <option key={i} value={roundVal}>
                            Round {roundVal}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    {/* {this.state.roundreg == 1 || this.state.roundreg == "" ? ( */}
                    <a
                      href="#"
                      className="button"
                      onClick={this.handleHeatForm}
                    >
                      Generate Heats
                    </a>
                    {/* ) : (
""
)} */}

                    {this.state.eventDetails.event_status == "upcoming" ? (
                      <EditHeatsheet />
                    ) : null}

                    {/* <NavLink
                      className="button"
                      to={"/download-heatsheet/" + this.state.eventId}
                    >
                      Download Heatsheets
                    </NavLink> */}
                    <NavLink
                      className="button"
                      to={"/download-new-heatsheet/" + this.state.eventId}
                    >
                      Download CD Heatsheets
                    </NavLink>
                    <NavLink
                      className="button"
                      to={"/download-participant-heatsheet/" + this.state.eventId}
                    >
                      Download Participant Heatsheets
                    </NavLink>
                    <NavLink
                      className="button"
                      to={"/download-excel-heatsheet/" + this.state.eventId}
                    >
                      Download Excel
                    </NavLink>
                    <NavLink
                      className="button"
                      to={"/email-participants-pdf/" + this.state.eventId}
                    >
                      Email Participants
                    </NavLink>
                    {this.state.eventDetails.transponder_allow == 1 ? (
                      <DownloadTransponderExcel
                        eventId={this.state.eventId}
                        runDates={this.state.eventDates}
                        timeZone={this.state.eventDetails.time_zone}
                      />
                    ) : (
                      ""
                    )}

                    {/* <NavLink className="button" to={"/download-fees/" + this.state.eventId}>Payments</NavLink> */}

                    <button
                      id="basic-button"
                      aria-controls={
                        openFormatSelect ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openFormatSelect ? "true" : undefined}
                      onClick={this.handleFormatClick}
                      className="downloadButton"
                      style={{
                        marginLeft: "10px",
                        display: "inline-block",
                        background: "#0095fd",
                        color: "white",
                        fontWeight: "600",
                        padding: "8px 35px",
                      }}
                    >
                      Check-in Sheet
                    </button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openFormatSelect}
                      onClose={this.handleFormatClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <NavLink
                        className="button"
                        to={"/download-payments/" + this.state.eventId}
                        style={{ margin: "10px 10px" }}
                      >
                        PDF
                      </NavLink>

                      <NavLink
                        className="button"
                        to={"/download-payments-excel/" + this.state.eventId}
                        style={{ margin: "10px 10px" }}
                      >
                        EXCEL
                      </NavLink>
                    </Menu>
                  </div>
                </div>

                {this.state.isLoder ? (
                  <div className="text-center mt-4 loadMore">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <>
                    {classes.map((item) => {
                      return (
                        <div>
                          <div className="mText">{item.name}</div>
                          <form id="myForm">
                            <div className="sheetList flexBox">
                              {this.state.eventParticipant
                                .sort((a, b) => a.heat_number - b.heat_number)
                                .map((value) => {
                                  let participantClassIds = [];
                                  value.participants.map((p) => {
                                    if (
                                      p.event_participant_class.class.id ==
                                      item.id
                                    )
                                      participantClassIds.push({
                                        heat: value.heat_number,
                                        participant_id: p.id,
                                        class_id:
                                          p.event_participant_class.class.id,
                                        event_participant_class_id:
                                          p.event_participant_class_id,
                                        class_heat_number: p.class_heat_number,
                                        scratch: p.scratch,
                                        finish: p.finish,
                                        ...p.event_participant_class
                                          .event_participant,
                                      });
                                  });

                                  let participantheats = [];
                                  participantClassIds.map((h) => {
                                    if (participantheats.indexOf(h.heat) > -1) {
                                    } else {
                                      participantheats.push(h.heat);
                                    }
                                  });

                                  return participantheats.indexOf(
                                    value.heat_number
                                  ) > -1 &&
                                    value.participants[0]
                                      .event_participant_class.class.id ===
                                      item.id ? (
                                    <div className="box">
                                      <div className="inner">
                                        <div className="hd flexBox itemCenter spacebetween">
                                          <div>Heat {value.heat_number}</div>

                                          <div>
                                            {/* <div style={{marginLeft:'30px'}}>Driver No. -{value.participants.length}</div> */}

                                            <span className="opcheckWrapper">
                                              <input
                                                type="checkbox"
                                                className="checkInpt"
                                                onChange={(e) =>
                                                  this.handleShowHeatBox(
                                                    e,
                                                    value
                                                  )
                                                }
                                              />
                                              {/* <i className="bg"></i> */}
                                            </span>
                                          </div>

                                          <div>
                                            Driver {value.participants.length}
                                          </div>
                                          <div className="mergeBtn d-flex">
                                            <a
                                              href="#"
                                              className="button"
                                              onClick={(e) =>
                                                this.handleSplit(
                                                  e,
                                                  participantClassIds,
                                                  value.heat_number
                                                )
                                              }
                                            >
                                              Split
                                            </a>

                                            <a
                                              href="#"
                                              className="button"
                                              onClick={(e) =>
                                                this.handleMerge(e, value, item)
                                              }
                                            >
                                              Merge
                                            </a>
                                          </div>

                                          {/* Dialouge box for heat selection */}
                                          <Modal isOpen={this.state.open}>
                                            <ModalHeader className="loginHeader">
                                              <Button className="close">
                                                <span
                                                  class="material-icons"
                                                  onClick={this.toggle}
                                                >
                                                  close
                                                </span>
                                              </Button>
                                            </ModalHeader>
                                            <ModalBody>
                                              {classes
                                                .filter((item) => {
                                                  return (
                                                    item.id ==
                                                    this.state.mergeClassId
                                                  );
                                                })
                                                .map((item) => {
                                                  return (
                                                    <div>
                                                      <div className="mText">
                                                        Class-{" "}
                                                        <span>{item.name}</span>
                                                      </div>
                                                      <form id="myForm">
                                                        <div className="sheetList1 column2 flexBox">
                                                          {this.state.eventParticipant
                                                            .filter((itm) => {
                                                              return (
                                                                itm.heat_number !=
                                                                this.state
                                                                  .mergeHeatNo
                                                                  .heat_number
                                                              );
                                                            })
                                                            .map((value) => {
                                                              let mParticipantClassIds =
                                                                [];
                                                              value.participants.map(
                                                                (p) => {
                                                                  if (
                                                                    p
                                                                      .event_participant_class
                                                                      .class
                                                                      .id ===
                                                                    item.id
                                                                  )
                                                                    mParticipantClassIds.push(
                                                                      {
                                                                        heat: value.heat_number,
                                                                        ...p
                                                                          .event_participant_class
                                                                          .event_participant,
                                                                      }
                                                                    );
                                                                }
                                                              );
                                                              let mParticipantheats =
                                                                [];
                                                              mParticipantClassIds.map(
                                                                (h) => {
                                                                  if (
                                                                    mParticipantheats.indexOf(
                                                                      h.heat
                                                                    ) > -1
                                                                  ) {
                                                                  } else {
                                                                    mParticipantheats.push(
                                                                      h.heat
                                                                    );
                                                                  }
                                                                }
                                                              );
                                                              return mParticipantheats.indexOf(
                                                                value.heat_number
                                                              ) > -1 ? (
                                                                <div className="box">
                                                                  <div className="inner">
                                                                    <div className="hd flexBox itemCenter spacebetween">
                                                                      <div>
                                                                        Heat No.
                                                                        :{" "}
                                                                        {
                                                                          value.heat_number
                                                                        }
                                                                      </div>
                                                                      <div>
                                                                        <span className="opcheckWrapper">
                                                                          <input
                                                                            type="checkbox"
                                                                            className="checkInpt"
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              this.handleShowHeatBox(
                                                                                e,
                                                                                value,
                                                                                this
                                                                                  .state
                                                                                  .mergeHeatNo
                                                                              )
                                                                            }
                                                                          />
                                                                          <i className="bg"></i>
                                                                        </span>
                                                                      </div>
                                                                    </div>

                                                                    {/* 

<div className="list" onDragOver={(event) => this.onDragOver(event, "heat" + det.heat_number)}
onDrop={(event) => this.onDrop(event, "heat" + det.heat_number, det)}>

{tasks["heat" + det.heat_number]}

</div> */}
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <></>
                                                              );
                                                            })}
                                                        </div>
                                                      </form>
                                                    </div>
                                                  );
                                                })}

                                              {this.state.eventParticipant
                                                .length != 0 && (
                                                <div
                                                  className="mt-4 text-end"
                                                  style={{ padding: "5px" }}
                                                >
                                                  <a
                                                    href="#"
                                                    className="blueBtn"
                                                    onClick={
                                                      this.handleMergeHeatAction
                                                    }
                                                  >
                                                    Merge Heats
                                                  </a>
                                                </div>
                                              )}
                                            </ModalBody>
                                          </Modal>

                                          {/* Dialouge box for split Heat */}

                                          <Modal isOpen={this.state.splitOpen}>
                                            <ModalHeader className="loginHeader">
                                              <Button className="close">
                                                <span
                                                  class="material-icons"
                                                  onClick={this.toggle}
                                                >
                                                  close
                                                </span>
                                              </Button>
                                            </ModalHeader>
                                            <ModalBody>
                                              <div>
                                                <form id="myForm">
                                                  <div className="sheetList column3 flexBox">
                                                    <div className="box">
                                                      <div className="inner">
                                                        <div className="hd flexBox itemCenter spacebetween">
                                                          <div>
                                                            Heat :{" "}
                                                            {this.state
                                                              .splitHeatNo
                                                              ? this.state
                                                                  .splitHeatNo
                                                              : ""}
                                                          </div>
                                                        </div>

                                                        <div
                                                          className="list1"
                                                          onDragOver={(
                                                            event,
                                                            det
                                                          ) =>
                                                            this.onDragOver(
                                                              event,
                                                              "heat" +
                                                                det.heat_number
                                                            )
                                                          }
                                                          onDrop={(
                                                            event,
                                                            det
                                                          ) =>
                                                            this.onDrop(
                                                              event,
                                                              "heat" +
                                                                det.heat_number,
                                                              det
                                                            )
                                                          }
                                                        >
                                                          {this.state.splitValues.map(
                                                            (sUser) => {
                                                              return (
                                                                <div
                                                                  onDragStart={(
                                                                    event,
                                                                    task
                                                                  ) =>
                                                                    this.onDragStart(
                                                                      event,
                                                                      task
                                                                    )
                                                                  }
                                                                  draggable
                                                                  className="draggable listItem"
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#fff",
                                                                    color:
                                                                      "01458b",
                                                                  }}
                                                                >
                                                                  {sUser.user
                                                                    .user_firstname +
                                                                    " " +
                                                                    sUser.user
                                                                      .user_lastname}

                                                                  <span className="opcheckWrapper1">
                                                                    <input
                                                                      type="checkbox"
                                                                      className="checkInpt"
                                                                      disabled={
                                                                        sUser.scratch
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        this.handleSplitCheckBox(
                                                                          e,
                                                                          sUser
                                                                        )
                                                                      }
                                                                    />
                                                                    <i className="bg"></i>
                                                                  </span>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                        <div
                                                          className="mt-4 text-end"
                                                          style={{
                                                            padding: "5px",
                                                          }}
                                                        >
                                                          <a
                                                            href="#"
                                                            className="blueBtn"
                                                            onClick={
                                                              this
                                                                .handleSplitHeatAction
                                                            }
                                                          >
                                                            Split Heat
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </form>
                                              </div>
                                            </ModalBody>
                                          </Modal>
                                        </div>

                                        <div
                                          className="list"
                                          onDragOver={(event) =>
                                            this.onDragOver(
                                              event,
                                              "heat" + value.heat_number
                                            )
                                          }
                                          onDrop={(event) =>
                                            this.onDrop(
                                              event,
                                              "heat" + value.heat_number,
                                              value
                                            )
                                          }
                                        >
                                          {participantClassIds.map((det) => {
                                            if (det.scratch == true) {
                                              return (
                                                <div
                                                  onClick={() =>
                                                    this.handleActivate(det)
                                                  }
                                                  onDragStart={(event) =>
                                                    this.onDragStart(event, det)
                                                  }
                                                  draggable
                                                  className="draggable listItem"
                                                  style={{
                                                    backgroundColor: "#bebfbf",
                                                    color: "#7C8686",
                                                  }}
                                                >
                                                  {det.user.user_firstname +
                                                    " " +
                                                    det.user.user_lastname}
                                                </div>
                                              );
                                            }
                                            if (det.scratch == false) {
                                              return (
                                                <div
                                                  onClick={() =>
                                                    this.handleDeActivate(det)
                                                  }
                                                  onDragStart={(event) =>
                                                    this.onDragStart(event, det)
                                                  }
                                                  draggable
                                                  className="draggable listItem"
                                                  style={{
                                                    backgroundColor: "#fff",
                                                    color: "01458b",
                                                  }}
                                                >
                                                  {det.user.user_firstname +
                                                    " " +
                                                    det.user.user_lastname}
                                                </div>
                                              );
                                            }
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  );
                                })}
                            </div>
                          </form>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const MyComponentWithNavigation = () => {
  const navigate = useNavigate();

  return <UncontrolledBoard navigate={navigate} />;
};

export default MyComponentWithNavigation;
