import Slider from "react-slick";
import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Layout/Header/Header";
import Footer from "../../Components/Layout/Footer/Footer";
import combinedServices from "../../shared/services/user-service";
import getLocalData from "../../shared/localStorage/getData";
import userConstants from "../../shared/constants/user-constants";
import moment from "moment";
import "./Home.scss";
import { NavLink, useNavigate } from "react-router-dom";
import playstore from '../../assets/images/playstore.webp'
import appstore from '../../assets/images/appstore.png'

const Home = () => {
  let navigate = useNavigate();
  const [highlight, setHighlite] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [baseURL, setBaseUrl] = useState(userConstants.baseURL);
  const [completedEventDetails, setCompletedEventDetails] = useState([]);
  const [districId, setDistricID] = useState("");
  const [homePageContent, setHomePageContent] = useState([]);
  const [allbannerImages, setAllbannerImages] = useState("");
  const [distric, setDistric] = useState([]);
  const [eventId, setEventId] = useState();
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [imgUrl, setImgUrl] = "";
  const [ongoingEvents, setOngoingEvents] = useState([]);
  const itemsPerPage = 5;
  const logo='https://nambaweb.s3.ap-south-1.amazonaws.com/frontend-static-image/logo.png'

  var settings = {
    dots: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  

  useEffect(async () => {
    // const played = sessionStorage.getItem('videoPlayed');
    // if (played) {
    //   setVideoPlayed(true);
    // }
    var eventArr = [];
    var completEventArr = [];
    var upcomingdata = {
      host_club_id: "",
      page: 1,
      records_per_page: 10,
      event_status: "upcoming",
      sort_by: "event_start",
      order: "ASC",
    };

    var ongoingdata = {
      host_club_id: "",
      page: 1,
      records_per_page: 10,
      event_status: "ongoing",
      sort_by: "event_start",
      order: "ASC",
    };

    var completedEventdata = {
      host_club_id: "",
      page: 1,
      records_per_page: 10,
      event_status: "completed",
      sort_by: "event_start",
      order: "DESC",
    };

    if (getLocalData("user_info", "isLogin")) {
      var userDistricId = getLocalData("user_info", "all")
       console.log("🚀 ~ useEffect ~ userDistricId:", userDistricId)
       
      userDistricId=userDistricId.userData
        .login_user_detail.data.user.district?.uzip_namba_district;
      var userId = getLocalData("user_info", "all").userData.login_user_detail
        .data.user.id;
      setDistricID(userDistricId);
      upcomingdata.district_id = userDistricId;
      // upcomingdata.district_id = userDistricId ;
      completedEventdata.district_id = userDistricId;
    }

    var data = {
      limit: 4,
      eventId: "",
    };

    let response = await combinedServices.getAllHighlite(data);
    if (response.status == 200) {
      setHighlite(response.data);
    }

    let eventResponse = await combinedServices.getAllEventForHomePage(
      upcomingdata
    );

    let ongoingResponse = await combinedServices.getAllEventForHomePage(
      ongoingdata
    );
    setOngoingEvents(ongoingResponse.data);

    let homePageContent = await combinedServices.homeContentDataAllHomePage();
    if (homePageContent.status == 200) {
      homePageContent.data.sort((a, b) => a.ordercontent - b.ordercontent);
      setHomePageContent(homePageContent.data);
    }
    let completeEventResponse = await combinedServices.getAllCompleted(
      completedEventdata
    );

    eventResponse.data.map((events) => {
      if (events.event_is_published) {
        var eventdata = {
          event_start: events.event_event_start,
          event_end: events.event_event_end,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          venue: events.venue_clbsite_site_name,
          hostClub_club_name: events.hostClub_club_name,
          event_image: events.event_image.includes("amazonaws")
            ? events.event_image
            : baseURL + "images/" + events.event_image,
          id: events.event_id,
          event_is_published: events.event_is_published,
          boat_count: events.boatsCount,
          racer_Count: events.racersCount,
          event_details: events,
          time_zone: events.event_time_zone,
        };
        eventArr.push(eventdata);
      }
    });

    setEventDetails(eventArr);

    completeEventResponse.data.map((events) => {
      if (events.event_is_published) {
        var completeventdata = {
          event_start: events.event_event_start,
          event_end: events.event_event_end,
          full_title: events.event_full_title,
          registration_close: events.event_registration_close,
          registration_open: events.event_registration_open,
          venue: events.venue_clbsite_site_name,
          hostClub_club_name: events.hostClub_club_name,
          event_image: events.event_image.includes("amazonaws")
            ? events.event_image
            : baseURL + "images/" + events.event_image,
          id: events.event_id,
          event_is_published: events.event_is_published,
          distric_id: events.event_district_id,
          time_zone: events.event_time_zone,
        };
        completEventArr.push(completeventdata);
      }
    });
    setEventDetails(eventArr);
    setCompletedEventDetails(completEventArr);

    let imageResponse = await combinedServices.bannerImageDataAll();
    setAllbannerImages(imageResponse.data[0].image);

    let response1 = await combinedServices.getAllDistric();
    setDistric(response1.data);
    // const videoElement = document.getElementById('yourVideoId');

    // const handleVideoEnd = () => {
    //   setVideoPlayed(true);
    //   sessionStorage.setItem('videoPlayed', 'true');
    // };

    // if (videoElement) {
    //   videoElement.addEventListener('ended', handleVideoEnd);
    //                                                                        // videoElement.play();

    //   return () => {
    //     videoElement.removeEventListener('ended', handleVideoEnd);
    //   };
    // }
  }, []);

  const handleEventDetailPage = (i) => {
    navigate("/event-detail/" + i);
  };
  const handleViewMoreHIghLight = (id) => {
    navigate("../highlights/" + id, { replace: true });
  };
  // const timeaccotolocation2 = (x, timeZone) => {
  //   const utcMoment = moment.utc(x);
  //   const localMoment = utcMoment.tz(timeZone);

  //   // const timeZoneAbbreviation = localMoment.format('z');
  //   const timeZoneOffset = localMoment.format('Z');

  //   return localMoment.toString();
  // }
  const handleVideoStart = () => {
    // const timeaccotolocation2 = (x, timeZone) => {
    // const video = document.getElementById('yourVideoId');
    // video.muted = false;
    //   const utcMoment = moment.utc(x);
    //   const localMoment = utcMoment.tz(timeZone);
    // vidRef.current.muted = false;
  };
  const handleVideoEnd = () => {
    setVideoPlayed(true);
    sessionStorage.setItem("videoPlayedFlag", true);
  };

  const vidRef = useRef();
  const timeaccotolocation2 = (x, timeZone) => {
    if (timeZone == "") {
      timeZone = "Canada/Eastern";
    }
    const utcMoment = moment.utc(x);
    const localMoment = utcMoment.tz(timeZone);

    // const timeZoneAbbreviation = localMoment.format('z');
    const timeZoneOffset = localMoment.format("Z");
    return localMoment.format("ddd MMM DD YYYY");
  };

  const regex1 = /<img.*?src="(.*?)"/;

  const removeImgTags = (htmlString) => {
    const regex = /<img[^>]*>/; // Regex pattern to match img tags
    return htmlString.replace(regex, ""); // Replace img tags with an empty string
  };

  const playStoreLink="https://play.google.com/store/apps/details?id=com.NambaRace"
  const appStoreLink="https://apps.apple.com/us/app/namba-res/id6449292731"

  return (
    <div className="mainCon whtBg">
      <>
        <Header />
        <div
          className="bannerCon"
          style={{
            background: `url(${
             allbannerImages.includes('amazonaws') ? allbannerImages: `${baseURL}` + "images/" + `${allbannerImages}`
            })`,
          }}
        >
          <div className="container flexBox itemCenter">
            
            <div className="bannerInfo flexBox">
              <div className="bannerWht">NAMBA International </div>
              <div className="bannerBlue">Race Entry System</div>
              <div className="download-links">
            <a href={playStoreLink} target="_blank"> <img src={playstore} className="playstore-img"/></a>
            <a href={appStoreLink} target="_blank">  <img src={appstore} className="appstore-img"/></a>
            </div>
            </div>
           
          </div>
        </div>
        <div className="sliderCon">
          <div className="container">
            {eventDetails.length > 0 && (
              <div className="upcomingSlider">
                <Slider>
                  {eventDetails.map((events, i) => {
                    return (
                      <div className="item" key={events.id}>
                        <div>
                          <div className="flexBox upcomingBox itemCenter">
                            <div className="UEDet">
                              <span>Upcoming Events</span>

                              <div>{events.full_title}</div>
                              <div>
                                {timeaccotolocation2(
                                  events.event_start,
                                  events.time_zone
                                )}
                                {timeaccotolocation2(
                                  events.event_start,
                                  events.time_zone
                                ) !=
                                  timeaccotolocation2(
                                    events.event_end,
                                    events.time_zone
                                  ) &&
                                  ` - ${timeaccotolocation2(
                                    events.event_end,
                                    events.time_zone
                                  )} (${
                                    events.time_zone == "HST"
                                      ? "Hawaiin"
                                      : events.time_zone.split("/")[1]
                                  })`}
                              </div>

                              <div>{events.venue} </div>
                              <br />
 
                              <div>
                                {events.racer_Count} Drivers /{" "}
                                {events.boat_count} Boats
                              </div>
                            </div>
                            <div
                              className="UEImage bb"
                              onClick={() => handleEventDetailPage(events.id)}
                            >
                              {events.event_image ==
                              `${baseURL}` + "images/" ? (
                                <img
                                  src={`/static/media/logo.d22ca8f16fd8e85ccbd9.png`}
                                />
                              ) : (
                                <img src={`${events.event_image}`} style={{height:'200px'}}/>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            )}
            {ongoingEvents.length > 0 && (
              <div className="upcomingSlider" >
                <Slider>
                  {ongoingEvents.map((events, i) => {
                    return (
                      <div className="item" key={events.id}>
                        <div>
                          <div className="flexBox upcomingBox itemCenter" >
                            <div className="UEDet">
                              <span>Live Events</span>

                              <div>{events.event_full_title}</div>
                              <div>
                                {timeaccotolocation2(
                                  events.event_event_start,
                                  events.event_time_zone
                                )}
                                {timeaccotolocation2(
                                  events.event_event_end,
                                  events.event_time_zone
                                ) !=
                                  timeaccotolocation2(
                                    events.event_event_end,
                                    events.event_time_zone
                                  ) &&
                                  ` - ${timeaccotolocation2(
                                    events.event_event_end,
                                    events.event_time_zone
                                  )} (${
                                    events.time_zone == "HST"
                                      ? "Hawaiin"
                                      : events.event_time_zone.split("/")[1]
                                  })`}
                              </div>

                              <div>{events.venue_clbsite_site_name} </div>
                              <br />
                              <div>
                                {events.racersCount} Drivers /{" "}
                                {events.boatsCount} Boats
                              </div>
                            </div>
                            <div className="UEImage bb">
                              <NavLink to={`/live-feed/${events.event_id}`}>
                                {events.event_image.includes('nambaweb') ? (
                                  <img src={events.event_image} style={{height:'200px'}}/>
                                ) : events.event_image.includes(baseURL) ? <img src={`${baseURL}images/${events.event_image}`}/>:<img src={logo}/>}
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            )}
          </div>
        </div>

        {completedEventDetails.length > 0 && (
          <div className="pt50 pb50">
            <div className="container">
              <div className="mainHd text-start mb-3">
                RECENTLY COMPLETED EVENT
              </div>
              <div className="contentBox mb-4">
                Here are some of our recently completed racing events and NAMBA
                Championships that were attended by boat racers from all over
                the world, their fans, families, kids, and just the boating
                enthusiasts. You will have details on the Championship winners,
                the cities that hosted the recent weeklong regatta, and a lot
                more.
              </div>
              <div className="eventTable">
                <table>
                  <thead>
                    <tr>
                      <th>Hosting Club</th>
                      <th>District</th>
                      <th>Date</th>
                      <th>Location</th>
                      <th>Result</th>
                    </tr>
                  </thead>
                  <tbody>
                    {completedEventDetails.map((eventValue) => {

                      return (
                        <>
                          <tr key={eventValue.event_id}>
                            <td>
                              <a href={"/event-detail/" + eventValue.id}>
                                {eventValue.hostClub_club_name}
                              </a>
                            </td>
  
                            <td>D{eventValue.distric_id}</td>
                            <td>
                              {" "}
                              {moment(
                                timeaccotolocation2(
                                  eventValue.event_start,
                                  eventValue.time_zone
                                )
                              ).format("MMM D")}
                              -
                              {moment(
                                timeaccotolocation2(
                                  eventValue.event_end,
                                  eventValue.time_zone
                                )
                              ).format("MMM D YYYY")}{" "}
                              (
                              {eventValue.time_zone == "HST"
                                ? "Hawaiin"
                                : eventValue.time_zone.split("/")[1]}
                              )
                            </td>

                            <td>{eventValue.venue}</td>
                            <td>
                              <a
                                href={"/club-result/" + eventValue.id}
                                className="view"
                              >
                                View
                              </a>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="paging">
                <ul>
                  {Array.from(
                    {
                      length: Math.ceil(
                        completedEventDetails.length / itemsPerPage
                      ),
                    },
                    (value, index) => (
                      <li key={index}>
                        <a>{index + 1}</a>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}

        {homePageContent.length > 0 && (
          <div className="container">
            <ul className="textlistBox">
              {homePageContent.map((homeData) => {
                return (
                  <li className="">
                    <div className="inner">
                      <div class="image">
                        <a href={homeData.url} target="_blank">
                          <img
                            src={
                              homeData.html.match(regex1)
                                ? homeData.html.match(regex1)[1]
                                : baseURL + "images/" + homeData.image
                            }
                            alt=""
                           
                          />
                        </a>
                      </div>
                      <div class="text">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: removeImgTags(homeData.html),
                          }}
                        />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {highlight.length > 0 && (
          <div className="pt50">
            <div className="container">
              <div className="flexBox itemCenter hdBox">
                <div className="mainHd">
                  HIGHLIGHTS<span></span>
                </div>
                <div className="hdText">
                  NAMBA has seen tremendous growth in more than three and a half
                  decades of its establishment.
                </div>
              </div>
              <div className="newsSlider">
                <Slider {...settings}>
                  {highlight.map((value, index) => {
                    return (
                      <div className="item">
                        {value.image_url.length > 0 && (
                          <div className="inner">
                            <div className="img transition">
                              <img
                                src={baseURL + "images/" + value.image_url[0]}
                                alt=""
                              />
                            </div>

                            <div className="highlightPoints">
                              <ul>
                                {value.points.map((points, pointIndex) => {
                                  return (
                                    <li key={index + "" + pointIndex}>
                                      {points}
                                    </li>
                                  );
                                })}
                              </ul>
                              <button
                                className="highligthsButton"
                                onClick={() =>
                                  handleViewMoreHIghLight(value.id)
                                }
                              >
                                {" "}
                                View More
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        )}

        <Footer />
      </>
    </div>
  );
};
export default Home;
